import React from 'react';
import styles from './ButtonRow.module.css';

interface ButtonRowProps extends React.ComponentProps<'div'> {
  alignment?: 'left' | 'right';
  paddingTop?: boolean;
}

export const ButtonRow = ({ children, alignment, paddingTop, className, ...rest }: ButtonRowProps) => {
  return (
    <div
      className={`${styles.root} ${alignment === 'left' ? styles.left : styles.right} ${
        paddingTop ? styles.paddingTop : ''
      } ${className || ''}`}
      {...rest}
    >
      {children}
    </div>
  );
};
