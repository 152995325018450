export const number = (value: number, precision = 0, minimum = 0): string => {
  const num = toFixed(value, precision);

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minimum,
    maximumFractionDigits: precision
  }).format(num);
};

export const currency = (
  value: number,
  currency: string,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
): string => {
  return new Intl.NumberFormat('en-US', {
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency'
  })
    .format(value)
    .replace(/^(\D+)/, '$1 ');
};

export const toFixed = (value: number, precision = 0): number => {
  const power = Math.pow(10, precision);
  return Math.round(value * power) / power;
};
