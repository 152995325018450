import React, { JSXElementConstructor, ReactElement } from 'react';
import { Button } from '../Button';
import { ButtonRow } from '../ButtonRow';
import { Modal } from '../Modal';

interface ConfirmationModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  title: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onReject?: () => void;
}

export const ConfirmationModal = ({
  children,
  title,
  open,
  loading,
  onClose,
  onConfirm,
  onReject
}: ConfirmationModalProps) => {
  const handleReject = () => {
    if (onReject) {
      onReject();
    } else {
      onClose();
    }
  };

  return (
    <Modal title={title} open={open} onClose={onClose}>
      <>
        {children}
        <ButtonRow paddingTop>
          <Button variant="outlined" onClick={handleReject}>
            No
          </Button>
          <Button onClick={onConfirm} loading={loading}>
            Yes
          </Button>
        </ButtonRow>
      </>
    </Modal>
  );
};
