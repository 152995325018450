import { yupResolver } from '@hookform/resolvers/yup';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from '../components/Anchor';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { TOKEN } from '../constants/localStorage';
import { useSetHeadings } from '../hooks/useSetHeadings';
import styles from '../layouts/Public.module.css';
import { api } from '../redux/api';
import { BillingPeriod, PlanType, RegisterParams, Tool } from '../redux/api.types';
import { setToken } from '../redux/auth';
import { useAppDispatch } from '../redux/hooks';

interface RegisterFields {
  name: string;
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  email: Yup.string().email('Invalid email address').required('This field is required'),
  password: Yup.string().required('This field is required').min(8, 'Password must be at least 8 characters long')
});

const billingPeriods: BillingPeriod[] = ['Annual', 'Monthly'];
const planTypes: PlanType[] = ['AgencyFullAccess', 'AgencyOneTool'];
const tools: Tool[] = [
  'Extension',
  'NicheFinder',
  'EasySource',
  'ProductTracker',
  'LoveHate',
  'KeywordsOnFire',
  'ListingOptimizer',
  'Dashboard',
  'KeywordTracker',
  'ProductMonitor',
  'IpMonitor',
  'EmailAutomator',
  'ReviewAutomator'
];

const RegisterContent = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidRegistrationLink, setInvalidRegistrationLink] = useState<boolean>(false);
  const urlParams = useParams();
  const billingPeriod = urlParams.billingPeriod as BillingPeriod;
  const planType = urlParams.planType as PlanType;
  const tool = urlParams.tool as Tool | undefined;
  const [registerAgency] = api.endpoints.register.useMutation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });
  const stripe = useStripe();
  const { enqueueSnackbar } = useSnackbar();

  const handleRegister = async ({ name, email, password }: RegisterFields) => {
    setIsLoading(true);

    const params: RegisterParams = {
      name,
      email,
      password,
      billingPeriod,
      planType
    };

    if (tool) params.tool = tool;

    try {
      const { accessToken, checkoutSessionId } = await registerAgency(params).unwrap();

      localStorage.setItem(TOKEN, accessToken);

      const stripeResult = await stripe?.redirectToCheckout({
        sessionId: checkoutSessionId
      });

      if (stripeResult?.error?.message) {
        enqueueSnackbar(stripeResult.error.message, { variant: 'error' });
        setIsLoading(false);
        dispatch(setToken(accessToken));
      }
    } catch {
      setIsLoading(false);
    }
  };

  useSetHeadings('Create an account');

  useEffect(() => {
    const wrongParam = (array: string[], param: string) =>
      array.findIndex((el) => el.toLowerCase() === param.toLowerCase()) === -1;
    const wrongBillingPeriod = wrongParam(billingPeriods, billingPeriod);
    const wrongPlanType = wrongParam(planTypes, planType);
    const wrongTool = tool && wrongParam(tools, tool);

    if (wrongBillingPeriod || wrongPlanType || wrongTool) {
      setInvalidRegistrationLink(true);
    }
  }, []); // eslint-disable-line

  return (
    <>
      {invalidRegistrationLink ? (
        <p className={styles.invalidRegistration}>
          The registration link is invalid.
          <br /> Please contact support.
        </p>
      ) : (
        <form className={styles.form} onSubmit={handleSubmit(handleRegister)}>
          <Input label="Agency Name" type="text" error={errors.name?.message} {...register('name')} />
          <Input label="Email" type="email" error={errors.email?.message} {...register('email')} />
          <Input label="Password" type="password" error={errors.password?.message} {...register('password')} />
          <Button type="submit" className={styles.loginBtn} fullWidth loading={isLoading}>
            Create
          </Button>
        </form>
      )}

      <div className={styles.helper}>
        Already have an account? <Link to="/login">Login</Link>
      </div>
    </>
  );
};

export const Register = () => {
  const { data: stripePublicApiKey } = api.endpoints.fetchPublicStripeToken.useQuery();
  const stripePromise = useMemo<Promise<Stripe | null> | null>(
    () => (stripePublicApiKey ? loadStripe(stripePublicApiKey) : null),
    [stripePublicApiKey]
  );

  return (
    <Elements stripe={stripePromise}>
      <RegisterContent />
    </Elements>
  );
};
