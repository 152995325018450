import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styles from './Anchor.module.css';

interface AnchorLinkProps extends LinkProps {
  children?: React.ReactNode;
  className?: string;
  size?: 'body' | 'cta' | 'label';
}

export const Link = ({ children, className, size, ...rest }: AnchorLinkProps) => {
  const sizeClass = () => {
    if (size) {
      return styles[size];
    }

    return styles.body;
  };

  return (
    <RouterLink className={`${styles.root} ${sizeClass()} ${className || ''}`} {...rest}>
      {children}
    </RouterLink>
  );
};
