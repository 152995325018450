import { useEffect, useState } from 'react';
import { TOKEN } from '../constants/localStorage';
import { api } from '../redux/api';
import { logout, setCurrentUser, setToken } from '../redux/auth';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

export const useAuthCheck = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [fetchCurrentUser] = api.endpoints.me.useMutation();
  const [isLoading, setIsLoading] = useState(true);

  // Fetch token from local storage and set it in the store
  useEffect(() => {
    if (!token) {
      const savedToken = localStorage.getItem(TOKEN);
      if (savedToken) {
        dispatch(setToken(savedToken));
      } else {
        setIsLoading(false);
      }
    }
  }, []); // eslint-disable-line

  // Check auth when the token changes
  useEffect(() => {
    if (token && !currentUser) {
      fetchCurrentUser()
        .unwrap()
        .then((profile) => dispatch(setCurrentUser(profile)))
        .catch(() => dispatch(logout()))
        .finally(() => setIsLoading(false));
    }
  }, [token]); // eslint-disable-line

  return isLoading;
};
