import React from 'react';
import { Button } from '../../components/Button';
import { ButtonRow } from '../../components/ButtonRow';
import { Modal } from '../../components/Modal';
import { AGENCY_DASHBOARD } from '../../constants/tracker';
import { tracker } from '../../utils/tracker';
import { Client } from './ClientsDatatable';

interface LoginClientModalProps {
  open: boolean;
  client: Client;
  onClose: () => void;
  redirectUrl?: string;
}

export const LoginClientModal = ({ open, client, onClose, redirectUrl }: LoginClientModalProps) => {
  const handleLoginClient = () => {
    window.open(redirectUrl, '_blank');

    tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.loggedInToClientAccount, {
      Email: client.email,
      'Account User Id': client.id,
      'Client Name': client.name
    });

    onClose();
  };

  return (
    <Modal title="Log In as Client?" open={open} onClose={onClose}>
      <>
        <div>
          ZonGuru App will open in new tab and you will be logged in as Client: <strong>{client.name}</strong>.<br />
          Are you sure you want to continue?
        </div>
        <ButtonRow paddingTop>
          <Button variant="outlined" onClick={onClose}>
            No
          </Button>
          <Button type="button" onClick={handleLoginClient}>
            Yes
          </Button>
        </ButtonRow>
      </>
    </Modal>
  );
};
