import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';
import { MetricType } from '../../../redux/api.types';
import styles from './MetricDetailsCellRenderer.module.css';
import { MetricDetailsModal } from './MetricDetailsModal';
import { WithPercentCellRenderer } from './WithPercentCellRenderer';
import { columnIds } from '../ClientsDatatable';

interface MetricDetailsCellRendererProps extends ICellRendererParams {
  metric: MetricType;
  columnId: string;
  inverted?: boolean;
}

export const MetricDetailsCellRenderer = (params: MetricDetailsCellRendererProps) => {
  const columnId = params.columnId;
  const [isMetricDetailsModalOpen, setIsMetricDetailsModalOpen] = useState(false);

  const openMetricDetailsModal = () => setIsMetricDetailsModalOpen(true);
  const closeMetricDetailsModal = () => setIsMetricDetailsModalOpen(false);

  return params.node.rowPinned ? (
    // Render footer total
    <WithPercentCellRenderer
      prevValue={params.data[columnId]?.prevValue}
      percent={params.data[columnId]?.difference}
      {...params}
    >
      {params.valueFormatted || params.value}
    </WithPercentCellRenderer>
  ) : (
    // Render normal row metric
    <WithPercentCellRenderer
      prevValue={params.data[columnId]?.prevValue}
      percent={params.data[columnId]?.difference}
      {...params}
    >
      {params.value ? (
        <>
          <button className={styles.link} onClick={openMetricDetailsModal}>
            {params.valueFormatted || params.value}
          </button>
          {isMetricDetailsModalOpen && (
            <MetricDetailsModal
              subAccountId={params.data[columnIds.id]}
              range={params.context.range}
              metric={params.metric}
              open={isMetricDetailsModalOpen}
              onClose={closeMetricDetailsModal}
            />
          )}
        </>
      ) : (
        params.valueFormatted
      )}
    </WithPercentCellRenderer>
  );
};
