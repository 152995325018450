import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../redux/api';
import { logout, setCurrentUser, setToken } from '../redux/auth';
import { useAppDispatch } from '../redux/hooks';
import { tracker } from '../utils/tracker';

export const AdminLoginAs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();
  const token = urlParams.token as string;
  const [adminLoginAs] = api.endpoints.adminLoginAs.useMutation();
  const [fetchCurrentUser] = api.endpoints.me.useMutation();

  const handleAdminLoginAs = async () => {
    try {
      const accessToken = await adminLoginAs({
        token: token
      }).unwrap();
      dispatch(logout());
      const currentUser = await fetchCurrentUser({ FbaToken: accessToken }).unwrap();
      // do not identify mixpanel user and optout of tracking
      tracker.optout();
      dispatch(setCurrentUser(currentUser));
      dispatch(setToken(accessToken));
      localStorage.setItem('token', accessToken);
    } catch {}

    navigate('/');
  };

  useEffect(() => {
    handleAdminLoginAs();
  }, []); // eslint-disable-line

  return null;
};
