import { ICellRendererParams } from 'ag-grid-community';
import React, { useEffect } from 'react';
import { Icon } from '../../../components/Icon';
import { amber, textDisabled } from '../../../constants/colors';
import { AGENCY_DASHBOARD } from '../../../constants/tracker';
import { tracker } from '../../../utils/tracker';
import { Client, Limit } from '../ClientsDatatable';
import styles from './LimitsCellRenderer.module.css';

export const LimitsCellRenderer = (params: ICellRendererParams) => {
  if (params.node.rowPinned) return null;

  const client = params.data as Client;
  if (!client) return null;

  return (
    <Icon
      icon="bell"
      size={16}
      color={client.anyLimitsReached ? amber : textDisabled}
      style={{ opacity: client.markedForDeletion ? 0.3 : 1 }}
      title={
        !client.markedForDeletion &&
        client.limits.length && <Limits limits={client.limits} anyLimitsReached={client.anyLimitsReached} />
      }
    />
  );
};

const LimitItem = ({ limit }: { limit: Limit }) => {
  return (
    <div className={`${styles.titleRow} ${limit.isLimitReached ? styles.limitsReached : ''}`} key={limit.name}>
      <div>{limit.name}:</div>
      <div>
        <strong>
          {limit.current}/{limit.max}
        </strong>
      </div>
    </div>
  );
};

const Limits = ({ limits, anyLimitsReached }: { limits: Limit[]; anyLimitsReached: boolean }) => {
  // Track only if tooltip is shown for at least 1.5sec
  useEffect(() => {
    const timeout = setTimeout(() => {
      tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.openedLimits, {
        'Any Limits Reached': anyLimitsReached
      });
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.title}>
      {limits.map((limit) => (
        <LimitItem key={limit.name} limit={limit} />
      ))}
    </div>
  );
};
