export const getCssVariable = (name: string): string =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

export const setCssVariable = (name: string, value: string): void => {
  document.querySelector<HTMLElement>(':root')?.style.setProperty(name, value);
};

export const getRelativeDifference = (currentValue: number, previousValue: number, decimalPlaces = 2): number => {
  if (previousValue === 0) return 0;

  const difference = ((currentValue - previousValue) / previousValue) * 100;
  return round(difference, decimalPlaces);
};

export const getAbsoluteDifference = (currentValue: number, previousValue: number, decimalPlaces = 2): number => {
  return round(currentValue - previousValue, decimalPlaces);
};

/**
 * Round half away from zero ('commercial' rounding)
 * Uses correction to offset floating-point inaccuracies.
 * Works symmetrically for positive and negative numbers.
 */
const round = (num: number, decimalPlaces = 0): number => {
  const p = Math.pow(10, decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};
