import { createTheme } from '@mui/material/styles';
import {
  error,
  primaryDark,
  primaryLight,
  primaryMain,
  secondaryDark,
  secondaryLight,
  secondaryMain,
  textDisabled,
  textPrimary,
  textSecondary
} from './constants/colors';
import { familyBody, weightBold, weightRegular, weightSemibold } from './constants/typography';

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryMain,
      dark: primaryDark,
      light: primaryLight
    },
    secondary: {
      main: secondaryMain,
      dark: secondaryDark,
      light: secondaryLight
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      disabled: textDisabled
    },
    error: {
      main: error
    }
  },
  typography: {
    fontFamily: familyBody,
    fontWeightRegular: weightRegular,
    fontWeightMedium: weightSemibold,
    fontWeightBold: weightBold
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: primaryMain,
          fontSize: '12px',
          fontWeight: weightBold,
          gap: '8px',
          padding: '12px 16px'
        }
      }
    }
  }
});
