import { IHeaderParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

interface DefaultHeaderRendererParams extends IHeaderParams {
  tooltip?: string;
}

export const DefaultHeaderRenderer = (props: DefaultHeaderRendererParams): JSX.Element => {
  const [sort, setSort] = useState<'asc' | 'desc' | null>(null);
  const [isMoving, setIsMoving] = useState(false);

  const onSortChanged = () => {
    if (props.column.isSortAscending()) {
      setSort('asc');
    } else if (props.column.isSortDescending()) {
      setSort('desc');
    } else {
      setSort(null);
    }
  };

  const onMovingChanged = () => {
    setIsMoving(true);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();

    props.column.addEventListener('movingChanged', onMovingChanged);
  }, []); // eslint-disable-line

  const content = <span className="ag-header-cell-text">{props.displayName}</span>;

  const handleSort = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isMoving && props.column.getColDef().sortable) {
      props.progressSort(event.shiftKey);
    }
  };

  return (
    <div
      className="ag-cell-label-container ag-header-cell-sorted-none"
      onClick={handleSort}
      onPointerUp={() => setIsMoving(false)}
    >
      <div className={`ag-header-cell-label ${sort ? 'ag-header-cell-label-sorted' : ''}`}>
        {props.tooltip ? <Tooltip title={props.tooltip}>{content}</Tooltip> : content}
        {props.column.getColDef().sortable && (
          <span className="ag-header-label-icon">
            <Icon
              style={{ display: 'flex', visibility: sort === 'desc' ? 'hidden' : 'initial' }}
              icon="chevron-up"
              size={10}
            />
            <Icon
              style={{ display: 'flex', visibility: sort === 'asc' ? 'hidden' : 'initial' }}
              icon="chevron-down"
              size={10}
            />
          </span>
        )}
      </div>
    </div>
  );
};
