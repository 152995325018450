import React from 'react';
import styles from './Anchor.module.css';

interface AnchorProps extends React.ComponentProps<'a'> {
  size?: 'body' | 'cta' | 'label';
}

export const Anchor = ({ children, className, size, ...rest }: AnchorProps) => {
  const sizeClass = () => {
    if (size) {
      return styles[size];
    }

    return styles.body;
  };

  return (
    <a className={`${styles.root} ${sizeClass()} ${className || ''}`} {...rest}>
      {children}
    </a>
  );
};
