import React, { forwardRef, JSXElementConstructor, Ref, SVGProps } from 'react';
import IcoMoon from 'react-icomoon';
import { Tooltip } from '../Tooltip';
import IconSet from './selection.json';

export interface IcoMoonProps extends SVGProps<SVGElement> {
  icon: string;
  size?: string | number;
  disableFill?: boolean;
  removeInlineStyle?: boolean;
  native?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SvgComponent?: JSXElementConstructor<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PathComponent?: JSXElementConstructor<any>;
}

export interface IconProps extends IcoMoonProps {
  title?: string | React.ReactNode;
}

const Icon = forwardRef(({ title, ...rest }: IconProps, ref: Ref<SVGElement>) =>
  title ? (
    <Tooltip title={title}>
      <span style={{ display: 'flex' }}>
        <IcoMoon ref={ref} iconSet={IconSet} {...rest} disableFill />
      </span>
    </Tooltip>
  ) : (
    <IcoMoon ref={ref} iconSet={IconSet} {...rest} disableFill />
  )
);

Icon.displayName = 'Icon';

export { Icon };
