import { yupResolver } from '@hookform/resolvers/yup';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from '../components/Anchor/Link';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { OverlayLoader } from '../components/OverlayLoader';
import { useSetHeadings } from '../hooks/useSetHeadings';
import { OutletContext } from '../layouts/Public';
import styles from '../layouts/Public.module.css';
import { api } from '../redux/api';

interface ResetPasswordFields {
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  password: Yup.string().required('This field is required').min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .required('This field is required')
    .min(8, 'Password must be at least 8 characters long')
    .oneOf([Yup.ref('password')], 'Passwords do not match')
});

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const setHeadings = useOutletContext<OutletContext>();
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [resetPassword, { isLoading: isResetLoading }] = api.endpoints.resetPassword.useMutation();
  const [checkResetToken, { isLoading: isCheckLoading, status }] = api.endpoints.checkResetPasswordToken.useMutation();
  const isLoading = isResetLoading || isCheckLoading || status === QueryStatus.uninitialized;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ResetPasswordFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });

  const handleResetPassword = async (values: ResetPasswordFields) => {
    if (token) {
      const message = await resetPassword({
        token: token,
        password: values.password
      }).unwrap();
      setHeadings('Password reset', message);
      setTokenIsValid(false); // Set tokenIsValid to false to hide the form
    } else {
      setTokenIsValid(false);
    }
  };

  useSetHeadings('Change Password?', 'Please enter your new password.');

  useEffect(() => {
    const invalidHeading = 'Invalid or expired link';
    const invalidSubHeading =
      'The password link you are using is invalid or has expired. Please try resetting your password again or contact us at support@zonguru.com.';
    // setTimeout will give us some animaiton time so that app overlay animation
    // doesn't stack with the current component overlay animation
    setTimeout(() => {
      (async () => {
        try {
          if (token) {
            const isTokenValid = await checkResetToken({
              token: token
            }).unwrap();
            if (!isTokenValid) {
              setHeadings(invalidHeading, invalidSubHeading);
            } else {
              setTokenIsValid(true);
            }
          }
        } catch {
          setHeadings(invalidHeading, invalidSubHeading);
        }
      })();
    }, 1000);
  }, []); // eslint-disable-line

  return (
    <>
      {!isLoading && (
        <>
          {tokenIsValid ? (
            <form className={styles.form} onSubmit={handleSubmit(handleResetPassword)}>
              <Input label="Password" type="password" error={errors.password?.message} {...register('password')} />
              <Input
                label="Confirm Password"
                type="password"
                error={errors.confirmPassword?.message}
                {...register('confirmPassword')}
              />
              <Button type="submit" className={styles.loginBtn} fullWidth loading={isLoading}>
                Change Password
              </Button>
            </form>
          ) : (
            <div className={(styles.helper, styles.marginTop40)}>
              Go back to <Link to="/login">Login</Link>
            </div>
          )}
        </>
      )}
      <OverlayLoader persistent={isLoading} delay={0} />
    </>
  );
};
