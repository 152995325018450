import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useAppSelector } from '../redux/hooks';

export const useLogErrors = () => {
  const error = useAppSelector((state) => state.logger.error);
  const { enqueueSnackbar } = useSnackbar();

  // Show logged errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [error]); // eslint-disable-line
};
