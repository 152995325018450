import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Icon } from '../../../components/Icon';
import { Tooltip } from '../../../components/Tooltip';
import { Client } from '../ClientsDatatable';
import styles from './NameCellRenderer.module.css';

export const NameCellRenderer = (params: ICellRendererParams) => {
  if (params.node.rowPinned) return <div className={styles.total}>TOTAL</div>;

  const client = params.data as Client;
  if (!client) return null;

  return (
    <div className={styles.root}>
      {client.markedForDeletion ? (
        <Icon
          className={`${styles.icon} ${styles.markedForDeletion}`}
          icon="warning"
          size={16}
          title="This client is currently being deleted"
        />
      ) : (
        <>
          {!client.mwsConnected && (
            <Icon className={styles.icon} icon="warning" size={16} title="Amazon Account not connected" />
          )}
          {client.mwsConnected && !client.adsConnected && (
            <Icon className={styles.icon} icon="warning" size={16} title="Amazon Advertising not connected" />
          )}
        </>
      )}
      <Tooltip
        title={
          <>
            <div className={styles.tooltipName}>{params.value}</div>
            <div>{client.email}</div>
          </>
        }
      >
        <div className={`ag-cell-value ${styles.name}`}>{params.value}</div>
      </Tooltip>
    </div>
  );
};
