import { styled, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import App from './App';
import './assets/css/index.css';
import { amber, coral, lightBlue, zgGreen } from './constants/colors';
import { sizeCta, weightSemibold } from './constants/typography';
import { theme } from './muiTheme';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { createPalette } from './utils/createPalette';
import { createTypography } from './utils/createTypography';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

createPalette();
createTypography();

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    font-size: ${sizeCta};
    font-weight: ${weightSemibold};
    box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  }
  &.SnackbarItem-variantError {
    background-color: ${coral};
  }
  &.SnackbarItem-variantWarning {
    background-color: ${amber};
  }
  &.SnackbarItem-variantSuccess {
    background-color: ${zgGreen};
  }
  &.SnackbarItem-variantInfo {
    background-color: ${lightBlue};
  }
`;

const gtmParams = {
  id: process.env.REACT_APP_GTM_ID as string
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GTMProvider state={gtmParams}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StyledSnackbarProvider
                maxSnack={5}
                hideIconVariant
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <App />
              </StyledSnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </IntercomProvider>
      </GTMProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
