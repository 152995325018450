import { Tooltip as MaterialTooltip, TooltipProps } from '@mui/material';
import React from 'react';
import styles from './Tooltip.module.css';

export const Tooltip = ({
  children,
  enterDelay,
  enterNextDelay,
  placement,
  componentsProps,
  ...rest
}: TooltipProps) => {
  return (
    <MaterialTooltip
      arrow
      enterDelay={enterDelay || 200}
      enterNextDelay={enterNextDelay || 200}
      placement={placement || 'top'}
      componentsProps={{
        tooltip: { className: styles.tooltip },
        arrow: { className: styles.arrow },
        ...componentsProps
      }}
      {...rest}
    >
      {children}
    </MaterialTooltip>
  );
};
