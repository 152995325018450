import { RowNode } from 'ag-grid-community';

const isNA = (value: unknown) => value === null || value === '' || value === 0 || value === undefined;

export const defaultFormatter = <T>(
  value: T,
  options?: {
    formatter?: (value: T) => string;
    isNA?: (value: T) => boolean;
  }
): string => {
  if (options?.isNA ? options.isNA(value) : isNA(value)) {
    return 'n/a';
  } else if (typeof options?.formatter === 'function') {
    return options.formatter(value);
  } else {
    return String(value);
  }
};

export const createComparator =
  (
    customIsNA: (value: any, data?: any) => boolean = isNA // eslint-disable-line @typescript-eslint/no-explicit-any
  ) =>
  (
    valueA: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    valueB: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    rowA: RowNode,
    rowB: RowNode,
    isInverted: boolean
  ): number => {
    if (valueA === valueB) return 0;
    if (customIsNA(valueA, rowA.data)) return isInverted ? -1 : 1;
    if (customIsNA(valueB, rowB.data)) return isInverted ? 1 : -1;

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1;
    }

    return valueA > valueB ? 1 : -1;
  };
