import mixpanel from 'mixpanel-browser';
import { User } from '../redux/auth';

export const tracker = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init: (): void => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (token) mixpanel.init(token, { persistence_name: `zg_agency_${token}_mixpanel` });
  },
  identify: (profile: User, isLogin = false): void => {
    if (isLogin) {
      mixpanel.clear_opt_in_out_tracking();
    }
    // Attach on user
    mixpanel.people.set({
      'User ID': profile.userId,
      'Agency ID': profile.agencyId,
      'Agency Name': profile.agencyName,
      $email: profile.email
    });
    // Attach on each sent event
    mixpanel.register({
      'Agency ID': profile.agencyId,
      'Agency Name': profile.agencyName
    });

    mixpanel.identify(String(profile.userId));
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (namespace: string, name: string, trackingProps?: { [key: string]: any }): void => {
    mixpanel.track(`${namespace}: ${name}`, { ...trackingProps, Tool: namespace });
  },
  reset: (): void => {
    mixpanel.clear_opt_in_out_tracking();
    mixpanel.reset();
  },
  optout: (): void => {
    mixpanel.opt_out_tracking();
  }
};
