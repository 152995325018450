import React from 'react';
import styles from './InfoIcon.module.css';
import { Icon } from '../Icon';

interface InfoIconProps {
  children?: React.ReactNode;
  size?: number;
}

export const InfoIcon = ({ children, size }: InfoIconProps) => {
  return <Icon className={styles.infoIcon} icon="info" size={size || 14} title={children} />;
};
