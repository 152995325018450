import { AgGridReact } from 'ag-grid-react';
import { addDays, differenceInDays, endOfDay, format, formatISO, startOfDay } from 'date-fns';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Range } from 'react-date-range';
import { useIntercom } from 'react-use-intercom';
import { Anchor } from '../../components/Anchor';
import { Button } from '../../components/Button';
import { DatePickerInput } from '../../components/DatePickerInput';
import { IconButton } from '../../components/IconButton';
import { Input } from '../../components/Input';
import { AGENCY_DASHBOARD } from '../../constants/tracker';
import { api } from '../../redux/api';
import { tracker } from '../../utils/tracker';
import { AddClientModal } from './AddClientModal';
import { ClientsDatatable, columnIds } from './ClientsDatatable';
import styles from './Dashboard.module.css';
import { InfoIcon } from '../../components/InfoIcon';

const initialRange = {
  range: {
    startDate: startOfDay(addDays(new Date(), -29)),
    endDate: endOfDay(new Date()),
    key: 'selection'
  },
  name: 'Last 30 Days'
};

export const Dashboard = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [currentRange, setCurrentRange] = useState<Range>(initialRange.range);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);

  const [fetchSubAccountsTrigger, { data: clients, isFetching: clientsLoading }] =
    api.endpoints.fetchSubAccounts.useLazyQuery();

  const fetchSubAccounts = (range: Range, skipCache = false) =>
    fetchSubAccountsTrigger({
      dateStart: formatISO(range.startDate ?? new Date()),
      dateEnd: formatISO(range.endDate ?? new Date()),
      clientNow: formatISO(new Date()),
      skipCache
    });

  const openAddClientModal = () => setIsAddClientModalOpen(true);
  const closeAddClientModal = () => setIsAddClientModalOpen(false);

  const { show: showIntercom } = useIntercom();

  const downloadCsv = () => {
    if (!gridRef?.current) return;
    let fileName = 'ZonGuru_Agency_Dashboard';
    if (currentRange.startDate && currentRange.endDate) {
      fileName += `_${format(currentRange.startDate, 'yyyy-MM-dd')}_${format(currentRange.endDate, 'yyyy-MM-dd')}`;
    }
    fileName += '.csv';

    gridRef.current.api.exportDataAsCsv({
      fileName,
      columnKeys: [
        columnIds.name,
        columnIds.email,
        columnIds.itemsSold,
        columnIds.revenue,
        columnIds.profit,
        columnIds.organicSales,
        columnIds.paidSales,
        columnIds.advertisingCost,
        columnIds.acos,
        columnIds.tacos
      ]
    });

    tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.downloadedCSV, {
      'Number of Clients': gridRef.current.api.getDisplayedRowCount()
    });
  };

  const trackSearchedClient = debounce((name: string) => {
    tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.searchedClient, {
      'Client Name': name
    });
  }, 1000);

  const filterClientNames = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    gridRef.current?.api.setQuickFilter(name);
    trackSearchedClient(name);
  }, 300);

  const changeDateRange = (range: Range, name: string) => {
    setCurrentRange(range);
    fetchSubAccounts(range);

    tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.changedMetricsPeriod, {
      'Start Date': range.startDate && formatISO(range.startDate),
      'End Date': range.endDate && formatISO(range.endDate),
      'Range Name': name,
      'Number of Days': range.endDate && range.startDate ? differenceInDays(range.endDate, range.startDate) + 1 : 'n/a'
    });
  };

  useEffect(() => {
    tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.openedDashboard);
    fetchSubAccounts(currentRange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.leftSection}>
          <Input
            iconStart="search"
            name="client"
            type="text"
            placeholder="Search for Client ..."
            className={styles.search}
            onChange={filterClientNames}
            noMarginBottom
          />
          <DatePickerInput initialRange={initialRange} onApply={changeDateRange} />
          <IconButton
            icon="refresh"
            size={16}
            className={`${styles.refreshButton} ${clientsLoading && !isAddClientModalOpen ? styles.isLoading : ''}`}
            disabled={clientsLoading && !isAddClientModalOpen}
            onClick={() => fetchSubAccounts(currentRange, true)}
          />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.companiesInfo}>
            <InfoIcon>
              If you need extra seats, <Anchor onClick={showIntercom}>click here</Anchor> to contact support.
            </InfoIcon>
            <strong>
              {clients?.quotas?.used ?? '--'}/{clients?.quotas?.total ?? '--'}
            </strong>
            seats used
          </div>
          <Button variant="outlined" icon="download-csv" onClick={downloadCsv}>
            Download .csv
          </Button>
          <Button icon="add" onClick={openAddClientModal} disabled={clientsLoading && !isAddClientModalOpen}>
            Add Client
          </Button>
          <AddClientModal
            open={isAddClientModalOpen}
            limitReached={clients?.quotas && clients.quotas.used >= clients.quotas.total}
            onClose={closeAddClientModal}
          />
        </div>
      </div>
      <ClientsDatatable
        ref={gridRef}
        clients={clients?.data || []}
        range={currentRange}
        loading={clientsLoading && !isAddClientModalOpen}
        noRowsComponentParams={{ openAddClientModal }}
      />
    </div>
  );
};
