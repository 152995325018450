import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import zonguru from '../assets/images/zonguru.svg';
import { Anchor } from '../components/Anchor';
import { ContainerBox } from '../components/ContainerBox';
import styles from './Public.module.css';

export type OutletContext = (main: string, sub?: string) => void;

export const Public = () => {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');

  const setHeadings: OutletContext = (main: string, sub = '') => {
    setHeading(main);
    setSubHeading(sub);
  };

  return (
    <div className={styles.root}>
      <img src={zonguru} className={styles.logo} />
      {/* Content */}
      <ContainerBox className={styles.container}>
        {heading && <div className={styles.heading}>{heading}</div>}
        {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
        <Outlet context={setHeadings} />
      </ContainerBox>
      <div className={styles.copywrite}>{new Date().getFullYear()} &copy; ZonGuru.com</div>
      <div>
        <Anchor href="https://zonguru.com/terms-of-service" size="cta" target="_blank" className={styles.policy}>
          Terms of Service
        </Anchor>
        <Anchor href="https://zonguru.com/privacy" size="cta" target="_blank" className={styles.policy}>
          Privacy Policy
        </Anchor>
        <Anchor href="https://zonguru.com/cookie-policy" size="cta" target="_blank" className={styles.policy}>
          Cookie Policy
        </Anchor>
      </div>
    </div>
  );
};
