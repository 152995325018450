import { ClickAwayListener } from '@mui/material';
import Popper from '@mui/material/Popper';
import { endOfDay, isEqual } from 'date-fns';
import React, { useRef, useState } from 'react';
import { DateRange, DefinedRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import { Button } from '../Button';
import { Input } from '../Input';
import { defaultRange, staticRanges } from './DatePickerInput.constants';
import './DatePickerInput.css';
import styles from './DatePickerInput.module.css';

interface DatePickerInputProps {
  initialRange?: {
    range: Range;
    name: string;
  };
  onApply?: (range: Range, rangeName: string) => void;
}

export const DatePickerInput = ({ initialRange, onApply }: DatePickerInputProps) => {
  const dateRangeRef = useRef<DateRange | null>(null);
  const [ranges, setRanges] = useState<Range[]>([initialRange?.range || defaultRange]);
  const [previousRanges, setPreviousRanges] = useState<Range[]>([initialRange?.range || defaultRange]);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRangeName, setSelectedRangeName] = useState(initialRange?.name || 'Today');

  const toggle = (event: React.MouseEvent<HTMLElement>) => {
    if (isOpen) {
      cancel();
    } else {
      setAnchorEl(event.currentTarget);
      setPreviousRanges(ranges);
      setIsOpen(true);
    }
  };

  const cancel = () => {
    if (isOpen) {
      setIsOpen(false);
      setRanges(previousRanges);
    }
  };

  const apply = () => {
    const [currentRange] = ranges;
    const { startDate: currentStartDate, endDate: currentEndDate } = currentRange;
    if (currentStartDate && currentEndDate) {
      const match = staticRanges.find((range) => {
        const { startDate, endDate } = range.range();
        if (startDate && endDate && isEqual(currentStartDate, startDate) && isEqual(currentEndDate, endDate)) {
          return true;
        }

        return false;
      });

      const rangeName = match?.label || 'Custom Range';
      setSelectedRangeName(rangeName);
      if (onApply) onApply(currentRange, rangeName);
    }

    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={cancel}>
      <div>
        <Input
          value={selectedRangeName}
          readOnly
          onClick={toggle}
          noMarginBottom
          iconStart="calendar"
          iconEnd="arrow-bottom"
          className={styles.input}
        />
        <Popper open={isOpen} anchorEl={anchorEl} placement="bottom-start">
          <div className={styles.datePicker}>
            <div>
              <DefinedRange
                onPreviewChange={(value) =>
                  //@ts-expect-error Method is not TS defined on DateRange class
                  dateRangeRef.current?.updatePreview(
                    //@ts-expect-error Method is not TS defined on DateRange class
                    value ? dateRangeRef.current?.calcNewSelection(value, typeof value === 'string') : null
                  )
                }
                ranges={ranges}
                onChange={(item) => setRanges([item.selection])}
                staticRanges={staticRanges}
                inputRanges={[]}
              />
              <div className={styles.actionRow}>
                <Button variant="ghost" onClick={cancel}>
                  Cancel
                </Button>
                <Button onClick={apply}>Apply</Button>
              </div>
            </div>
            <DateRange
              months={2}
              direction="horizontal"
              ranges={ranges}
              onChange={(item) =>
                setRanges([
                  {
                    key: item.selection.key,
                    startDate: item.selection.startDate,
                    endDate: item.selection.endDate ? endOfDay(item.selection.endDate) : undefined
                  }
                ])
              }
              ref={(t) => (dateRangeRef.current = t)}
              dateDisplayFormat="MM/d/yyyy"
              editableDateInputs
              showMonthAndYearPickers={false}
              maxDate={endOfDay(new Date())}
              shownDate={new Date()}
              showPreview={false}
              weekdayDisplayFormat="EEEEEE"
              calendarFocus="backwards"
            />
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
