import { SortChangedEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import React, { forwardRef, Ref } from 'react';
import { OverlayLoader } from '../OverlayLoader';
import './Datatable.css';
import styles from './Datatable.module.css';
import { DefaultHeaderRenderer } from './DefaultHeaderRenderer';

interface DatatableProps extends AgGridReactProps {
  loading?: boolean;
  noRowsComponent?: React.ReactNode;
  noRowsComponentParams?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  skippedAutoSizeColumns?: string[];
}

const Datatable = forwardRef(
  (
    {
      loading,
      noRowsComponent,
      noRowsComponentParams,
      onRowDataChanged,
      onSortChanged,
      defaultColDef,
      skippedAutoSizeColumns,
      ...rest
    }: DatatableProps,
    ref: Ref<AgGridReact>
  ) => {
    const newDefaultColDef = {
      headerComponent: DefaultHeaderRenderer,
      ...defaultColDef
    };
    return (
      <div className={`ag-theme-alpine ${styles.datatable}`}>
        <AgGridReact
          ref={ref}
          animateRows
          headerHeight={62}
          rowHeight={70}
          suppressCellFocus
          suppressRowClickSelection
          suppressDragLeaveHidesColumns
          suppressColumnVirtualisation
          enableCellTextSelection
          defaultColDef={newDefaultColDef}
          autoSizePadding={0}
          noRowsOverlayComponent={noRowsComponent}
          noRowsOverlayComponentParams={noRowsComponentParams}
          onRowDataChanged={(event) => {
            if (event.api.getDisplayedRowCount() === 0) {
              event.api.sizeColumnsToFit();
            }

            // Autosize columns
            if (skippedAutoSizeColumns) {
              const columns =
                event.columnApi
                  .getAllColumns()
                  ?.filter((c) => skippedAutoSizeColumns.indexOf(c.getColId()) === -1)
                  .map((c) => c.getColId()) || [];
              event.columnApi.autoSizeColumns(columns);
            } else {
              event.columnApi.autoSizeAllColumns();
            }

            event.api.sizeColumnsToFit();

            if (onRowDataChanged) onRowDataChanged(event);
          }}
          onSortChanged={(event: SortChangedEvent) => {
            // Sets grid scroll to top on sort
            event.api.ensureIndexVisible(0, 'top');

            // Needed to apply the new css class when sorting
            event.api.refreshCells();

            if (onSortChanged) onSortChanged(event);
          }}
          {...rest}
        />

        <OverlayLoader persistent={loading} />
      </div>
    );
  }
);

Datatable.displayName = 'Datatable';

export { Datatable };
