import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../redux/api';
import { setCurrentUser } from '../redux/auth';
import { useAppDispatch } from '../redux/hooks';

export const CheckoutCallback = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');
  const sessionId = searchParams.get('sessionId');
  const [checkoutSession] = api.endpoints.checkoutSession.useMutation();

  const handleCheckoutSession = async (value: string) => {
    try {
      const { checkoutResponse, profile } = await checkoutSession({
        sessionId: value
      }).unwrap();

      dispatch(setCurrentUser(profile));

      /*
        Tapfiliate integration - https://tapfiliate.com/docs/integrations/stripe/
        Tapfiliate libraries are loaded via GTM. They need to load on all *.zonguru.com subdomains in order to associate
        commissions via tap('detect') any time a user lands on the page
        Wrap in try/catch just in case, we don't want any tapfiliate exceptions to stop our registration flow
        */
      try {
        if (checkoutResponse.isTrial) {
          window.tap('trial', checkoutResponse.stripeCustomerId);
        } else {
          window.tap('conversion', checkoutResponse.latestInvoiceChargeId, checkoutResponse.latestInvoiceAmount, {
            customer_id: checkoutResponse.stripeCustomerId
          });
        }
      } catch (e) {
        //ignore this error but log in case we ever debug
        console.log(e);
      }
    } catch {}

    navigate('/');
  };

  useEffect(() => {
    if (result == 'success' && sessionId) {
      handleCheckoutSession(sessionId);
    } else {
      navigate('/');
    }
  }, []); // eslint-disable-line

  return null;
};
