import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear
} from 'date-fns';
import { StaticRange, createStaticRanges } from 'react-date-range';

export const defaultRange = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  key: 'selection'
};

export const staticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -6)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 15 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -14)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -29)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 3 Months',
    range: () => ({
      startDate: startOfDay(addMonths(addDays(new Date(), 1), -3)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 6 Months',
    range: () => ({
      startDate: startOfDay(addMonths(addDays(new Date(), 1), -6)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 1 Year',
    range: () => ({
      startDate: startOfDay(addYears(addDays(new Date(), 1), -1)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Month to Date',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Quarter to Date',
    range: () => ({
      startDate: startOfQuarter(new Date()),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Year to Date',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfDay(new Date())
    })
  }
] as StaticRange[]);
