export const AGENCY_PROFILE = {
  namespace: 'Agency Profile',
  events: {
    loggedIn: 'Logged In',
    loggedOut: 'Logged Out',
    openedStripeCustomerPortal: 'Opened Stripe Customer Portal'
  }
};

export const AGENCY_DASHBOARD = {
  namespace: 'Agency Dashboard',
  events: {
    openedDashboard: 'Opened Dashboard',
    addedSubAccount: 'Added Sub-Account',
    deletedSubAccount: 'Deleted Sub-Account',
    searchedClient: 'Searched Client',
    downloadedCSV: 'Downloaded CSV',
    openedMetricDetailsGraph: 'Opened Metric Details Graph',
    openedLimits: 'Opened Limits',
    loggedInToClientAccount: 'Logged In to Client Account',
    changedMetricsPeriod: 'Changed Metrics Period',
    changedClientAccountPassword: 'Changed Client Account Password'
  }
};
