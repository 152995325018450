import React, { forwardRef, Ref } from 'react';
import { Icon } from '../Icon';
import styles from './Input.module.css';
import { InfoIcon } from '../InfoIcon';

interface InputProps extends React.ComponentProps<'input'> {
  label?: string;
  error?: string;
  info?: React.ReactNode;
  iconStart?: 'search' | 'calendar';
  iconEnd?: 'arrow-bottom';
  noMarginBottom?: boolean;
}

const Input = forwardRef(
  (
    { label, name, error, info, iconStart, iconEnd, className, noMarginBottom, ...rest }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <div className={`${styles.root} ${noMarginBottom ? styles.noMarginBottom : ''} ${className || ''}`}>
        {label && (
          <label htmlFor={name} className={`${styles.label} ${rest.disabled ? styles.disabled : ''}`}>
            {label}
            {info && <InfoIcon size={12}>{info}</InfoIcon>}
          </label>
        )}
        <div className={`${styles.inputWrapper} ${error ? styles.invalid : ''}`}>
          {iconStart && <Icon className={`${styles.icon} ${styles.iconStart}`} icon={iconStart} size={14} />}
          <input
            ref={ref}
            name={name}
            className={`${styles.input} ${iconStart ? styles.hasIconStart : ''} ${iconEnd ? styles.hasIconEnd : ''}`}
            {...rest}
          />
          {iconEnd && <Icon className={`${styles.icon} ${styles.iconEnd}`} icon={iconEnd} size={14} />}
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
