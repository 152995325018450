import { CircularProgress, ClickAwayListener, MenuItem, Paper, Popper } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React, { MouseEvent, useState } from 'react';
import { IconButton } from '../../../components/IconButton';
import { Tooltip } from '../../../components/Tooltip';
import { api } from '../../../redux/api';
import { FetchClientLoginInfoResponse } from '../../../redux/api.types';
import { Client } from '../ClientsDatatable';
import { DeleteClientModal } from '../DeleteClientModal';
import { LoginClientModal } from '../LoginClientModal';
import styles from './ActionsCellRenderer.module.css';
import { Icon } from '../../../components/Icon';
import { ChangeClientPasswordModal } from '../ChangeClientPasswordModal';

export const ActionCellRenderer = (params: ICellRendererParams) => {
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [isChangeClientPasswordModalOpen, setIsChangeClientPasswordModalOpen] = useState(false);
  const [clientLoginInfo, setClientLoginInfo] = useState<FetchClientLoginInfoResponse | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [fetchClientLoginInfo, { isLoading: fetchClientsLoading }] = api.endpoints.fetchClientLoginInfo.useMutation();

  if (params.node.rowPinned) return null;

  const client = params.data as Client;
  if (!client) return null;

  const isMenuOpen = Boolean(menuAnchorEl);
  const toggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (!menuAnchorEl) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      closeMenu();
    }
  };

  const closeMenu = (event?: Event | React.SyntheticEvent) => {
    if (event && menuAnchorEl && menuAnchorEl.contains(event.target as HTMLElement)) {
      return;
    }

    setMenuAnchorEl(null);
  };

  const openDeleteClientModal = () => {
    !client.markedForDeletion && setIsDeleteClientModalOpen(true);
    closeMenu();
  };

  const closeDeleteClientModal = () => !client.markedForDeletion && setIsDeleteClientModalOpen(false);

  const openChangeClientPasswordModal = () => {
    !client.markedForDeletion && setIsChangeClientPasswordModalOpen(true);
    closeMenu();
  };

  const closeChangeClientPasswordModal = () => !client.markedForDeletion && setIsChangeClientPasswordModalOpen(false);

  const openLoginClientModal = async () => {
    try {
      const loginInfo = await fetchClientLoginInfo({
        subAccountId: client.id
      }).unwrap();
      setClientLoginInfo(loginInfo);
    } catch {}
  };

  const closeLoginClientModal = () => setClientLoginInfo(null);

  return (
    <div className={styles.root}>
      {fetchClientsLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Tooltip title={!client.markedForDeletion ? 'Login' : ''}>
          <IconButton icon="login" onClick={openLoginClientModal} disabled={client.markedForDeletion} />
        </Tooltip>
      )}
      <Tooltip title={!client.markedForDeletion ? 'More Actions' : ''}>
        <IconButton icon="more_vertical" onClick={toggleMenu} disabled={client.markedForDeletion} />
      </Tooltip>
      <Popper
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        placement="bottom-end"
        container={document.querySelector('.ag-body-viewport')}
      >
        <ClickAwayListener onClickAway={closeMenu}>
          <Paper className={styles.menu}>
            <MenuItem onClick={openChangeClientPasswordModal}>
              <Icon icon="password_open" size={16} />
              Change Password
            </MenuItem>
            <MenuItem onClick={openDeleteClientModal}>
              <Icon icon="delete" size={16} />
              Delete
            </MenuItem>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {isDeleteClientModalOpen && (
        <DeleteClientModal open client={client} onClose={closeDeleteClientModal}></DeleteClientModal>
      )}
      {isChangeClientPasswordModalOpen && (
        <ChangeClientPasswordModal
          open
          client={client}
          onClose={closeChangeClientPasswordModal}
        ></ChangeClientPasswordModal>
      )}
      {clientLoginInfo && (
        <LoginClientModal
          open
          client={client}
          onClose={closeLoginClientModal}
          redirectUrl={clientLoginInfo?.redirectUrl}
        ></LoginClientModal>
      )}
    </div>
  );
};
