import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button } from '../components/Button';
import { ButtonRow } from '../components/ButtonRow';
import { Input } from '../components/Input';
import { Modal } from '../components/Modal';
import { api } from '../redux/api';

interface ChangePasswordFields {
  oldPassword: string;
  newPassword: string;
}

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('This field is required'),
  newPassword: Yup.string().required('This field is required').min(8, 'Password must be at least 8 characters long')
});

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

export const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps) => {
  const [changedPasswordMessage, setChangedPasswordMessage] = useState('');
  const [changePassword, { isLoading }] = api.endpoints.changePassword.useMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ChangePasswordFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });

  const handleChangePassword = async (values: ChangePasswordFields) => {
    try {
      const message = await changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      }).unwrap();
      setChangedPasswordMessage(message);
    } catch {}
  };

  const close = () => {
    onClose();
    setChangedPasswordMessage('');
    reset();
  };

  return (
    <Modal title="Change my Password" open={open} onClose={close}>
      {changedPasswordMessage ? (
        <>
          <div>{changedPasswordMessage}</div>
          <ButtonRow paddingTop>
            <Button onClick={close}>Done</Button>
          </ButtonRow>
        </>
      ) : (
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <Input
            label="Old Password"
            type="password"
            error={errors.oldPassword?.message}
            {...register('oldPassword')}
          />
          <Input
            label="New Password"
            type="password"
            error={errors.newPassword?.message}
            {...register('newPassword')}
          />
          <ButtonRow paddingTop>
            <Button variant="outlined" onClick={close} loading={isLoading}>
              Cancel
            </Button>
            <Button type="submit" loading={isLoading}>
              Save
            </Button>
          </ButtonRow>
        </form>
      )}
    </Modal>
  );
};
