import { Client, Quotas } from '../pages/Dashboard/ClientsDatatable';
import { User } from './auth';

export interface Response<T> {
  message: string;
  content: T;
}

export interface LoginParams {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export interface AdminLoginAsParams {
  token: string;
}

export interface ForgotPasswordParams {
  email: string;
}

export interface ResetPasswordParams {
  token: string;
  password: string;
}

export interface ResetPasswordResponse {
  token: string;
}

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export interface CheckResetPasswordParams {
  token: string;
}

export interface CheckResetPasswordResponse {
  isValid: boolean;
}

export interface CheckoutSessionParams {
  sessionId: string;
}

export interface CheckoutSessionResponse {
  checkoutResponse: {
    couponId: number | null;
    isTrial: boolean;
    latestInvoiceAmount: number;
    latestInvoiceChargeId: number | null;
    planCost: number;
    planCostCurrency: string;
    planId: string;
    stripeCustomerId: string;
  };
  profile: User;
}

export interface MeResponse {
  profile: User;
}

export type BillingPeriod = 'Annual' | 'Monthly';
export type PlanType = 'AgencyFullAccess' | 'AgencyOneTool';
export type Tool =
  | 'Extension'
  | 'NicheFinder'
  | 'EasySource'
  | 'ProductTracker'
  | 'LoveHate'
  | 'KeywordsOnFire'
  | 'ListingOptimizer'
  | 'Dashboard'
  | 'KeywordTracker'
  | 'ProductMonitor'
  | 'IpMonitor'
  | 'EmailAutomator'
  | 'ReviewAutomator';

export interface RegisterParams {
  name: string;
  email: string;
  password: string;
  billingPeriod: BillingPeriod;
  planType: PlanType;
  tool?: Tool;
}

export interface RegisterResponse {
  accessToken: string;
  checkoutSessionId: string;
}

export interface IntercomVerificationResponse {
  userId: number;
  email: string;
  hash: string;
  name: string;
}

export interface CreateSubAccountParams {
  name: string;
  email: string;
  password?: string;
}

export interface CreateSubAccountResponse {
  id: number;
  name: string;
}

export interface ChangeSubAccountPasswordParams {
  subAccountId: number;
  password: string;
}

export interface FetchClientLoginInfoResponse {
  redirectUrl: string;
}

export interface FetchSubAccountsParams {
  clientNow: string;
  dateStart: string;
  dateEnd: string;
  skipCache: boolean;
}

export interface SubAccountParams {
  subAccountId: number;
}

export interface SubAccountsResponse {
  data: Client[];
  quotas: Quotas;
}

export interface FetchMetricDetailsParams {
  clientNow: string;
  dateStart: string;
  dateEnd: string;
  metric: MetricType;
  subAccountId: number;
}

export interface MetricDetailsResponse {
  metric: MetricType;
  dailyMetrics: DailyMetric[];
}

export interface DailyMetric {
  date: Date;
  current: number | null;
  previous: number | null;
  trendLine: number | null;
}

export enum MetricType {
  ItemsSold = 'ItemsSold',
  Revenue = 'Revenue',
  Profit = 'Profit',
  OrganicSales = 'OrganicSales',
  PaidSales = 'PaidSales',
  AdvertisingCost = 'AdvertisingCost',
  Acos = 'Acos',
  Tacos = 'Tacos'
}
