import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '../layouts/Public';

export const useSetHeadings = (main: string, sub = '') => {
  const setHeadings = useOutletContext<OutletContext>();

  useEffect(() => {
    setHeadings(main, sub);
  }, []); // eslint-disable-line
};
