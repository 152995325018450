import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Error {
  message: string;
  status: number | 'FETCH_ERROR' | 'PARSING_ERROR' | 'CUSTOM_ERROR';
}

interface InitialState {
  error: Error | null;
}

const initialState: InitialState = {
  error: null
};

export const slice = createSlice({
  name: 'logger',
  initialState,
  reducers: {
    logError: (state, { payload: error }: PayloadAction<Error>) => {
      state.error = error;
    }
  }
});

export const { logError } = slice.actions;

export default slice.reducer;
