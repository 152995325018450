import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styles from './OverlayLoader.module.css';

interface OverlayLoaderProps {
  children?: React.ReactNode;
  delay?: number;
  duration?: number;
  size?: number;
  persistent?: boolean;
  marginTop?: number;
}

export const OverlayLoader = ({ children, duration, delay, size, persistent, marginTop }: OverlayLoaderProps) => {
  const animation = useRef<Animation | undefined>();
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!persistent) {
      animation.current = overlayRef.current?.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: duration !== undefined ? duration : 300,
        delay: delay !== undefined ? delay : 1000
      });

      animation.current?.finished.then(() => {
        setIsLoading(false);
      });
    } else {
      animation.current?.cancel();
      setIsLoading(true);
    }
  }, [persistent]); // eslint-disable-line

  return isLoading ? (
    <div
      ref={overlayRef}
      className={styles.root}
      style={{
        paddingTop: marginTop ? marginTop + 'px' : 0,
        alignItems: marginTop ? 'initial' : 'center'
      }}
    >
      {children || <CircularProgress size={size || 80} />}
    </div>
  ) : null;
};
