export const blue = '#6f77ef';
export const turquoise = '#4ed2c1';
export const keppel = '#40ac9e';
export const lightCyan = '#c5eee9';
export const bubbles = '#e8f7fa';

export const carbon = '#424242';
export const dust = '#838383';
export const silverSand = '#c4c4c4';

export const grey = '#e5e5e5';
export const whiteSmoke = '#f7f7f7';
export const justWhite = '#fff';

export const zgYellow = '#ffbb0b';
export const lemonCurry = '#d19a0a';
export const navajoWhite = '#f9d89d';

export const zgRed = '#ff4814';
export const coral = '#ff805b';
export const sinopia = '#d13b11';

export const rainForest = '#03584f';
export const greenSheen = '#65a69f';
export const blueGreen = '#034941';

export const deepPurple = '#712674';
export const purple = '#8333e9';
export const pink = '#ff5976';

export const indigo = '#4a50b2';
export const lightBlue = '#42a5f5';
export const cyan = '#70cde5';

export const deepOrange = '#eb6d18';
export const orange = '#ff9100';
export const amber = '#ffa939';

export const zgGreen = '#6cca51';
export const lime = '#cddc39';
export const lightGreen = '#c0eb6a';

export const primaryMain = blue;
export const primaryLight = lightBlue;
export const primaryDark = indigo;

export const secondaryMain = turquoise;
export const secondaryLight = lightCyan;
export const secondaryDark = keppel;

export const textPrimary = carbon;
export const textSecondary = dust;
export const textDisabled = silverSand;
export const textContrast = justWhite;

export const error = coral;
