import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TOKEN } from '../constants/localStorage';
import {
  AdminLoginAsParams,
  ChangePasswordParams,
  ChangeSubAccountPasswordParams,
  CheckResetPasswordParams,
  CheckResetPasswordResponse,
  CheckoutSessionParams,
  CheckoutSessionResponse,
  CreateSubAccountParams,
  CreateSubAccountResponse,
  FetchClientLoginInfoResponse,
  FetchMetricDetailsParams,
  FetchSubAccountsParams,
  ForgotPasswordParams,
  IntercomVerificationResponse,
  LoginParams,
  LoginResponse,
  MeResponse,
  MetricDetailsResponse,
  RegisterParams,
  RegisterResponse,
  ResetPasswordParams,
  Response,
  SubAccountParams,
  SubAccountsResponse
} from './api.types';
import { User, logout } from './auth';
import { logError } from './logger';
import { RootState } from './store';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token || localStorage.getItem(TOKEN);
    if (token) {
      headers.set('FbaToken', token);
    }
    return headers;
  }
});

const baseQueryWithInterceptors: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    let message = 'Oops! Something went wrong.';

    if (result.error.status === 'FETCH_ERROR' || result.error.status === 'CUSTOM_ERROR') {
      message = result.error.error;
    } else if (result.error.status === 'PARSING_ERROR') {
      message = result.error.data || result.error.error;
    } else if (result.error.data) {
      const data = result.error.data as { message: string };
      if (data.message) message = data.message;
    }

    console.log('API Error:', result.error);
    api.dispatch(logError({ message, status: result.error.status }));

    // Logout the user if status is 401
    if (
      result.error.status === 401 ||
      (result.error.status === 'PARSING_ERROR' && result.error.originalStatus === 401) // In case we can't parse the auth token correctly
    ) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithInterceptors,
  tagTypes: ['Client'],
  endpoints: (builder) => ({
    login: builder.mutation<string, LoginParams>({
      query: (params) => ({
        url: '/user/login',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<LoginResponse>) => response.content.token
    }),
    forgotPassword: builder.mutation<string, ForgotPasswordParams>({
      query: (params) => ({
        url: '/user/forgotPassword',
        method: 'POST',
        body: params
      })
    }),
    resetPassword: builder.mutation<string, ResetPasswordParams>({
      query: (params) => ({
        url: '/user/resetPassword',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<string>) => response.content
    }),
    changePassword: builder.mutation<string, ChangePasswordParams>({
      query: (params) => ({
        url: '/user/changePassword',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<string>) => response.message
    }),
    checkResetPasswordToken: builder.mutation<boolean, CheckResetPasswordParams>({
      query: (params) => ({
        url: '/user/checkResetPasswordToken',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<CheckResetPasswordResponse>) => response.content.isValid
    }),
    register: builder.mutation<RegisterResponse, RegisterParams>({
      query: (params) => ({
        url: '/user/register',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<RegisterResponse>) => response.content
    }),
    me: builder.mutation<User, { FbaToken: string } | void>({
      query: (headers) => ({ url: '/user/me', headers: headers || undefined }),
      transformResponse: (response: Response<MeResponse>) => response.content.profile
    }),
    checkoutSession: builder.mutation<CheckoutSessionResponse, CheckoutSessionParams>({
      query: (params) => ({
        url: '/user/handleCheckoutSession',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<CheckoutSessionResponse>) => response.content
    }),
    fetchPublicStripeToken: builder.query<string, void>({
      query: () => '/settings/stripePublicApiKey',
      transformResponse: (response: Response<string>) => response.content
    }),
    fetchIntercomVerification: builder.mutation<IntercomVerificationResponse, void>({
      query: () => '/intercom/getUserVerification',
      transformResponse: (response: Response<IntercomVerificationResponse>) => response.content
    }),
    createSubAccount: builder.mutation<CreateSubAccountResponse, CreateSubAccountParams>({
      query: (params) => ({
        url: '/subAccount/create',
        method: 'POST',
        body: params
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Client']),
      transformResponse: (response: Response<CreateSubAccountResponse>) => response.content
    }),
    changePasswordSubAccount: builder.mutation<null, ChangeSubAccountPasswordParams>({
      query: (params) => ({
        url: '/subAccount/changePassword',
        method: 'POST',
        body: params
      })
    }),
    deleteSubAccount: builder.mutation<string, SubAccountParams>({
      query: (params) => ({
        url: '/subAccount/delete',
        method: 'POST',
        body: params
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Client'])
    }),
    fetchClientLoginInfo: builder.mutation<FetchClientLoginInfoResponse, SubAccountParams>({
      query: (params) => ({
        url: '/subAccount/GenerateLoginAsToken',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<FetchClientLoginInfoResponse>) => response.content
    }),
    fetchSubAccounts: builder.query<SubAccountsResponse, FetchSubAccountsParams>({
      query: (params) => ({
        url: '/subAccount/all',
        method: 'POST',
        body: params
      }),
      providesTags: ['Client'],
      transformResponse: (response: Response<SubAccountsResponse>) => response.content
    }),
    fetchMetricDetails: builder.mutation<MetricDetailsResponse, FetchMetricDetailsParams>({
      query: (params) => ({
        url: '/subAccount/details',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<MetricDetailsResponse>) => response.content
    }),
    fetchNewBillingSession: builder.mutation<string, void>({
      query: () => ({
        url: '/user/getNewBillingSession',
        method: 'GET'
      }),
      transformResponse: (response: Response<string>) => response.content
    }),
    adminLoginAs: builder.mutation<string, AdminLoginAsParams>({
      query: (params) => ({
        url: '/user/adminLoginAs',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: Response<LoginResponse>) => response.content.token
    })
  })
});
