import { scaleLinear } from 'd3-scale';
import { format, formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Modal } from '../../../components/Modal';
import { OverlayLoader } from '../../../components/OverlayLoader';
import { AGENCY_DASHBOARD } from '../../../constants/tracker';
import { api } from '../../../redux/api';
import { DailyMetric, MetricType } from '../../../redux/api.types';
import { currency, number } from '../../../utils/format';
import { getCssVariable } from '../../../utils/helpers';
import { tracker } from '../../../utils/tracker';
import './MetricDetailsModal.css';
import styles from './MetricDetailsModal.module.css';

const trendLineStartPoint = format(new Date(Date.now()), 'dd MMM yyyy');

// Place the 'Periodic start point' line on today's date

// change type to see that the overlap might not be appropriate towards the
// end of the shorter chart
const type = 'linear';

interface MetricDetailsModalProps {
  range: Range;
  subAccountId: number;
  metric: MetricType;
  open: boolean;
  onClose: () => void;
}

const metricTitleTable = new Map([
  [MetricType.ItemsSold, 'Items Sold'],
  [MetricType.Revenue, 'Revenue'],
  [MetricType.Profit, 'Profit'],
  [MetricType.OrganicSales, 'Organic Sales'],
  [MetricType.PaidSales, 'Paid Sales'],
  [MetricType.AdvertisingCost, 'Advertising Cost'],
  [MetricType.Acos, 'ACOS'],
  [MetricType.Tacos, 'TACoS']
]);

const formatNumber = (type: MetricType, value: number, isOnAxis = false) => {
  switch (type) {
    case MetricType.ItemsSold:
      return number(value);
    case MetricType.OrganicSales:
    case MetricType.PaidSales:
    case MetricType.Revenue:
    case MetricType.Profit:
    case MetricType.AdvertisingCost:
      return currency(value, 'USD', isOnAxis ? 0 : 2);
    case MetricType.Acos:
    case MetricType.Tacos:
    default:
      return number(value, isOnAxis ? 0 : 2, isOnAxis ? 0 : 2);
  }
};

export const MetricDetailsModal = ({ range, subAccountId, metric, open, onClose }: MetricDetailsModalProps) => {
  const [dailyMetrics, setDailyMetrics] = useState<DailyMetric[]>();
  const [fetchMetricDetails, { isLoading: metricsLoading }] = api.endpoints.fetchMetricDetails.useMutation();

  const showTrendLineStartPoint = dailyMetrics?.find((d) => {
    const formattedDate = format(new Date(d.date), 'dd MMM yyyy');
    return formattedDate == trendLineStartPoint;
  });

  const getYAxisWidth = () => {
    const minWidth = 30;

    if (!dailyMetrics) return minWidth;

    let min = 0;
    let max = 0;

    dailyMetrics.forEach((item) => {
      const current = item.current ? item.current : 0;
      const previous = item.previous ? item.previous : 0;
      min = Math.min(min, current, previous);
      max = Math.max(max, current, previous);
    });

    const [minNumber, maxNumber] = scaleLinear().domain([min, max]).nice().domain();
    const maxLength = Math.max(
      formatNumber(metric, minNumber, true).length,
      formatNumber(metric, maxNumber, true).length
    );

    return Math.max(maxLength * 8, minWidth);
  };

  const title = metricTitleTable.get(metric) ?? '';

  useEffect(() => {
    if (!open) return;

    fetchMetricDetails({
      metric,
      subAccountId,
      clientNow: formatISO(new Date()),
      dateStart: formatISO(range.startDate ?? new Date()),
      dateEnd: formatISO(range.endDate ?? new Date())
    })
      .unwrap()
      .then((response) => {
        setDailyMetrics(response.dailyMetrics);
        tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.openedMetricDetailsGraph, {
          Metric: title
        });
      })
      .catch(onClose);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;

  return (
    <Modal title={title} open={open} onClose={onClose} width={736}>
      <div style={{ height: '355px' }}>
        {dailyMetrics && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={dailyMetrics}>
              <CartesianGrid strokeDasharray="3" />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tickMargin={8}
                interval="preserveStartEnd"
                tickFormatter={(tickItem: Date) => format(new Date(tickItem), 'MMM dd')}
              />
              <YAxis
                width={getYAxisWidth()}
                axisLine={false}
                tickLine={false}
                tickMargin={4}
                tickFormatter={(tickItem: number) => formatNumber(metric, tickItem, true)}
              />
              <Tooltip
                content={<GraphTooltip metric={metric} />}
                labelStyle={{ color: getCssVariable('--color-zg-yellow') }}
              />
              <Legend align="right" height={50} wrapperStyle={{ paddingTop: 30 }} />
              <Line
                type={type}
                dataKey="previous"
                stroke={getCssVariable('--color-secondary-light')}
                dot={false}
                name={`Previous ${title}`}
                legendType="plainline"
              />
              <Line
                type={type}
                dataKey="current"
                stroke={getCssVariable('--color-secondary-main')}
                dot={false}
                name={title}
                legendType="plainline"
              />
              <Line
                type={type}
                dataKey="trendLine"
                dot={false}
                name={`Trend line for ${title}`}
                legendType="plainline"
                stroke={getCssVariable('--color-secondary-main')}
                strokeDasharray="5 5"
                // Default animation time for drawing a line is 1500ms. Setting this to 1200ms will make drawing of the two lines to overlap by a tiny bit and it will look like one line is being drawn
                animationBegin={1200}
              />
              {showTrendLineStartPoint && (
                <ReferenceLine
                  x={trendLineStartPoint}
                  stroke={getCssVariable('--color-text-secondary')}
                  strokeWidth={2}
                  strokeDasharray="5 5"
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
        <OverlayLoader persistent={metricsLoading} delay={0} marginTop={60} />
      </div>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GraphTooltip = ({ active, payload, label, metric }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <strong className={styles.title}>{format(new Date(label), 'MMMM dd, yyyy')}</strong>
        {payload[1] && (
          <div style={{ color: payload[1].stroke }}>
            {payload[1].name}: <strong>{formatNumber(metric, payload[1].value)}</strong>
          </div>
        )}
        {payload[0] && (
          <div style={{ color: payload[0].stroke }}>
            {payload[0].name}: <strong>{formatNumber(metric, payload[0].value)}</strong>
          </div>
        )}
      </div>
    );
  }

  return null;
};
