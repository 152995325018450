import { CircularProgress } from '@mui/material';
import React, { forwardRef, Ref } from 'react';
import { Icon } from '../Icon';
import styles from './Button.module.css';

interface ButtonProps extends React.ComponentProps<'button'> {
  loading?: boolean;
  variant?: 'outlined' | 'ghost' | 'filled';
  fullWidth?: boolean;
  icon?: 'add' | 'download-csv';
  iconSize?: number;
  iconColor?: string;
  disabled?: boolean;
}

const Button = forwardRef(
  (
    {
      children,
      className,
      type,
      variant,
      fullWidth,
      icon,
      iconSize,
      iconColor,
      loading,
      disabled,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        type={type ? type : 'button'}
        className={`
          ${styles.root}
          ${variant ? styles[variant] : styles.filled}
          ${fullWidth ? styles.fullWidth : ''}
          ${className || ''}
        `}
        disabled={loading || disabled}
        {...rest}
      >
        {icon && !loading && <Icon icon={icon} size={iconSize ? iconSize : 14} color={iconColor} />}{' '}
        {loading && <CircularProgress size={16} color="inherit" />} {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export { Button };
// eslint-disable-next-line prettier/prettier
