import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';
import * as Yup from 'yup';
import { Anchor } from '../../components/Anchor';
import { Button } from '../../components/Button';
import { ButtonRow } from '../../components/ButtonRow';
import { Input } from '../../components/Input';
import { Modal } from '../../components/Modal';
import { AGENCY_DASHBOARD } from '../../constants/tracker';
import { api } from '../../redux/api';
import { tracker } from '../../utils/tracker';
import { CreateSubAccountParams } from '../../redux/api.types';

interface ClientFields {
  name: string;
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .notRequired()
    .nullable()
    .min(8, 'Password must be at least 8 characters long')
    .transform((val) => val || null) // pass min len is 8 but it's optional, accept null/empty string
});

interface AddClientModalProps {
  open: boolean;
  limitReached?: boolean;
  onClose: () => void;
}

export const AddClientModal = ({ open, limitReached, onClose }: AddClientModalProps) => {
  const [createSubAccount, { isLoading }] = api.endpoints.createSubAccount.useMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ClientFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });
  const { show: showIntercom } = useIntercom();

  const handleAddClient = async (values: ClientFields) => {
    try {
      const params: CreateSubAccountParams = {
        email: values.email,
        name: values.name,
        password: values.password
      };

      await createSubAccount(params).unwrap();

      tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.addedSubAccount, {
        Email: values.email,
        'Client Name': values.name,
        'Entered Password': !!values.password
      });

      close();
    } catch {}
  };

  const close = () => {
    onClose();
    reset();
  };

  return (
    <Modal title="Add Client" open={open} onClose={close}>
      <>
        {limitReached ? (
          <div>
            <div>
              Oops, it seems you&apos;ve reached your limit.
              <br />
              If you need extra seats, <Anchor onClick={showIntercom}>click here</Anchor> to contact support.
            </div>
            <ButtonRow paddingTop>
              <Button type="button" loading={isLoading} onClick={close}>
                Got it
              </Button>
            </ButtonRow>
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleAddClient)}>
            <Input
              label="Client Name"
              placeholder="Enter Client's Name"
              autoComplete="off"
              error={errors.name?.message}
              {...register('name')}
            />
            <Input
              label="Email"
              type="email"
              placeholder="Enter Client's Email"
              autoComplete="off"
              error={errors.email?.message}
              info="To receive accurate notifications and avoid missing out on some of the tool's features, kindly provide a valid email address. For added convenience and organization, you may consider using email aliases (Ex. info+client1/2/3@yourcompany.com)"
              {...register('email')}
            />
            <Input
              label="Password (optional)"
              type="password"
              placeholder="Enter Client's Password"
              autoComplete="new-password"
              error={errors.password?.message}
              info="This password can be used to log into the ZonGuru Web App. If you choose not to specify password, instructions on how to set one will be sent to the entered email address. Additionally, you can change the password at any time through the 'Actions' menu."
              {...register('password')}
            />
            <ButtonRow paddingTop>
              <Button type="submit" loading={isLoading}>
                Add
              </Button>
            </ButtonRow>
          </form>
        )}
      </>
    </Modal>
  );
};
