import {
  amber,
  blue,
  blueGreen,
  bubbles,
  carbon,
  coral,
  cyan,
  deepOrange,
  deepPurple,
  dust,
  greenSheen,
  grey,
  indigo,
  justWhite,
  keppel,
  lemonCurry,
  lightBlue,
  lightCyan,
  lightGreen,
  lime,
  navajoWhite,
  orange,
  pink,
  purple,
  rainForest,
  silverSand,
  sinopia,
  turquoise,
  whiteSmoke,
  zgGreen,
  zgRed,
  zgYellow
} from '../constants/colors';
import { setCssVariable } from './helpers';

export const createPalette = () => {
  setCssVariable('--color-turquoise', turquoise);
  setCssVariable('--color-keppel', keppel);
  setCssVariable('--color-light-cyan', lightCyan);
  setCssVariable('--color-bubbles', bubbles);

  setCssVariable('--color-carbon', carbon);
  setCssVariable('--color-dust', dust);
  setCssVariable('--color-silver-sand', silverSand);

  setCssVariable('--color-grey', grey);
  setCssVariable('--color-white-smoke', whiteSmoke);
  setCssVariable('--color-just-white', justWhite);

  setCssVariable('--color-zg-yellow', zgYellow);
  setCssVariable('--color-lemon-curry', lemonCurry);
  setCssVariable('--color-navajo-white', navajoWhite);

  setCssVariable('--color-zg-red', zgRed);
  setCssVariable('--color-coral', coral);
  setCssVariable('--color-sinopia', sinopia);

  setCssVariable('--color-rain-forest', rainForest);
  setCssVariable('--color-green-sheen', greenSheen);
  setCssVariable('--color-blue-green', blueGreen);

  setCssVariable('--color-deep-purple', deepPurple);
  setCssVariable('--color-purple', purple);
  setCssVariable('--color-pink', pink);

  setCssVariable('--color-indigo', indigo);
  setCssVariable('--color-blue', blue);
  setCssVariable('--color-light-blue', lightBlue);
  setCssVariable('--color-cyan', cyan);

  setCssVariable('--color-deep-orange', deepOrange);
  setCssVariable('--color-orange', orange);
  setCssVariable('--color-amber', amber);

  setCssVariable('--color-zg-green', zgGreen);
  setCssVariable('--color-lime', lime);
  setCssVariable('--color-light-green', lightGreen);

  setCssVariable('--color-primary-main', blue);
  setCssVariable('--color-primary-light', lightBlue);
  setCssVariable('--color-primary-dark', indigo);

  setCssVariable('--color-secondary-main', turquoise);
  setCssVariable('--color-secondary-light', lightCyan);
  setCssVariable('--color-secondary-dark', keppel);

  setCssVariable('--color-text-primary', carbon);
  setCssVariable('--color-text-secondary', dust);
  setCssVariable('--color-text-disabled', silverSand);
  setCssVariable('--color-text-contrast', justWhite);

  setCssVariable('--color-error', coral);
};
