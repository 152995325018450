import { Modal as MaterialModal } from '@mui/material';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { IconButton } from '../IconButton';
import styles from './Modal.module.css';

export type ModalCloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

interface ModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  title: string;
  open: boolean;
  width?: number;
  onClose: (reason?: ModalCloseReason) => void;
}

export const Modal = ({ children, title, open, width, onClose }: ModalProps) => {
  const onModalClose = (event: React.SyntheticEvent, reason: ModalCloseReason) => {
    event.stopPropagation();
    onClose(reason);
  };

  const onCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose('closeButtonClick');
  };

  return (
    <MaterialModal
      open={open}
      onClose={onModalClose}
      container={document.getElementById('agency-dashboard-content')}
      componentsProps={{ root: { className: styles.modal } }}
      BackdropProps={{ componentsProps: { root: { className: styles.backdrop } } }}
    >
      <div className={styles.root} style={width ? { width: `${width}px` } : undefined}>
        <div className={styles.header}>
          {title}
          <IconButton icon="close" size={14} className={styles.closeButton} onClick={onCloseClick} />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </MaterialModal>
  );
};
