import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { OverlayLoader } from './components/OverlayLoader';
import { useAuthCheck } from './hooks/useAuthCheck';
import { useLogErrors } from './hooks/useLogErrors';
import { Private } from './layouts/Private';
import { Public } from './layouts/Public';
import { AdminLoginAs } from './pages/AdminLoginAs';
import { CheckoutCallback } from './pages/CheckoutCallback';
import { Dashboard } from './pages/Dashboard';
import { ForgotPassword } from './pages/ForgotPassword';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ResetPassword } from './pages/ResetPassword';
import { api } from './redux/api';
import { useAppSelector } from './redux/hooks';
import { tracker } from './utils/tracker';

const App = () => {
  const { boot, shutdown } = useIntercom();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const isLoading = useAuthCheck();
  const [fetchIntercomVerification] = api.endpoints.fetchIntercomVerification.useMutation();

  useLogErrors();

  // Set mixpanel
  useEffect(() => {
    tracker.init();
    if (!isLoading && currentUser) {
      tracker.identify(currentUser);
    }
  }, [currentUser, isLoading]);

  // Set intercom
  useEffect(() => {
    if (!isLoading) {
      shutdown();

      if (currentUser) {
        fetchIntercomVerification()
          .unwrap()
          .then((verification) => {
            boot({
              userId: verification.userId.toString(),
              email: verification.email,
              userHash: verification.hash,
              name: verification.name
            });
          })
          .catch(boot);
      } else {
        boot();
      }
    }
  }, [currentUser, isLoading]); // eslint-disable-line

  return (
    <BrowserRouter>
      {!isLoading && (
        <Routes>
          <Route element={currentUser ? <Private /> : <Navigate to="/login" />}>
            <Route index element={<Dashboard />}></Route>
          </Route>
          <Route element={!currentUser ? <Public /> : <Navigate to="/" />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="register/:billingPeriod/:planType" element={<Register />} />
            <Route path="register/:billingPeriod/:planType/:tool" element={<Register />} />
          </Route>
          <Route path="/checkout-callback" element={<CheckoutCallback />} />
          <Route path="/admin-login-as/:token" element={<AdminLoginAs />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <OverlayLoader persistent={isLoading} delay={0} />
    </BrowserRouter>
  );
};

export default App;
