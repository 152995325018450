import {
  familyBody,
  lineHeight,
  sizeBody,
  sizeCta,
  sizeH1,
  sizeH2,
  sizeH3,
  sizeH4,
  sizeLabel,
  sizeSubtitle,
  weightBold,
  weightRegular,
  weightSemibold
} from '../constants/typography';
import { setCssVariable } from './helpers';

export const createTypography = () => {
  setCssVariable('--font-family-body', familyBody);
  setCssVariable('--font-weight-regular', weightRegular);
  setCssVariable('--font-weight-semibold', weightSemibold);
  setCssVariable('--font-weight-bold', weightBold);

  setCssVariable('--font-size-h1', sizeH1);
  setCssVariable('--font-size-h2', sizeH2);
  setCssVariable('--font-size-h3', sizeH3);
  setCssVariable('--font-size-h4', sizeH4);
  setCssVariable('--font-size-subtitle', sizeSubtitle);
  setCssVariable('--font-size-body', sizeBody);
  setCssVariable('--font-size-cta', sizeCta);
  setCssVariable('--font-size-label', sizeLabel);

  setCssVariable('--line-height', lineHeight);
};
