import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOKEN } from '../constants/localStorage';
import { AGENCY_PROFILE } from '../constants/tracker';
import { tracker } from '../utils/tracker';

export interface User {
  agencyId: string;
  agencyName: string;
  email: string;
  userId: number;
  hasActiveSubscription: boolean;
}

interface InitialState {
  token: string | null;
  currentUser: User | null;
}

const initialState: InitialState = {
  token: null,
  currentUser: null
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload: token }: PayloadAction<string>) => {
      state.token = token;
    },
    setCurrentUser: (state, { payload: currentUser }: PayloadAction<User>) => {
      state.currentUser = currentUser;
    },
    logout: () => {
      tracker.track(AGENCY_PROFILE.namespace, AGENCY_PROFILE.events.loggedOut);
      tracker.reset();
      localStorage.removeItem(TOKEN);
      return initialState;
    }
  }
});

export const { setToken, setCurrentUser, logout } = slice.actions;

export default slice.reducer;
