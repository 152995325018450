import { CircularProgress } from '@mui/material';
import React, { forwardRef, Ref } from 'react';
import { Icon } from '../Icon';
import styles from './IconButton.module.css';

interface IconButtonProps extends React.ComponentProps<'button'> {
  icon: 'login' | 'more_vertical' | 'close' | 'refresh';
  loading?: boolean;
  size?: number;
}

const IconButton = forwardRef(
  ({ className, type, icon, loading, size, disabled, ...rest }: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
    return (
      <button
        ref={ref}
        type={type ? type : 'button'}
        className={`
          ${styles.root}
          ${className || ''}
        `}
        disabled={disabled || loading}
        {...rest}
      >
        {loading ? <CircularProgress size={size || 20} /> : <Icon icon={icon} size={size || 20} />}
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';

export { IconButton };
