import React from 'react';
import { Button } from '../../components/Button';
import { ButtonRow } from '../../components/ButtonRow';
import { Modal } from '../../components/Modal';
import { AGENCY_DASHBOARD } from '../../constants/tracker';
import { api } from '../../redux/api';
import { tracker } from '../../utils/tracker';
import { Client } from './ClientsDatatable';

interface DeleteClientModalProps {
  open: boolean;
  client: Client;
  onClose: () => void;
}

export const DeleteClientModal = ({ open, client, onClose }: DeleteClientModalProps) => {
  const [deleteSubAccount, { isLoading }] = api.endpoints.deleteSubAccount.useMutation();

  const handleDeleteClient = async () => {
    try {
      await deleteSubAccount({
        subAccountId: client.id
      }).unwrap();
      tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.deletedSubAccount, {
        Email: client.email,
        'Account User Id': client.id,
        'Client Name': client.name
      });
      onClose();
    } catch {}
  };

  return (
    <Modal title="Delete Client?" open={open} onClose={onClose}>
      <>
        <div>
          Are you sure you want to delete <b>{client.name}</b>?
        </div>
        <ButtonRow paddingTop>
          <Button variant="outlined" onClick={onClose}>
            No
          </Button>
          <Button type="button" loading={isLoading} onClick={handleDeleteClient}>
            Yes
          </Button>
        </ButtonRow>
      </>
    </Modal>
  );
};
