import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button } from '../../components/Button';
import { ButtonRow } from '../../components/ButtonRow';
import { Input } from '../../components/Input';
import { Modal } from '../../components/Modal';
import { AGENCY_DASHBOARD } from '../../constants/tracker';
import { api } from '../../redux/api';
import { tracker } from '../../utils/tracker';
import { Client } from './ClientsDatatable';

interface ChangeClientPasswordFields {
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

interface ChangeClientPasswordModalProps {
  open: boolean;
  client: Client;
  onClose: () => void;
}

export const ChangeClientPasswordModal = ({ open, client, onClose }: ChangeClientPasswordModalProps) => {
  const [changePasswordSubAccount, { isLoading }] = api.endpoints.changePasswordSubAccount.useMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ChangeClientPasswordFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });

  const handleChangePassword = async (values: ChangeClientPasswordFields) => {
    try {
      await changePasswordSubAccount({
        subAccountId: client.id,
        password: values.password
      }).unwrap();

      tracker.track(AGENCY_DASHBOARD.namespace, AGENCY_DASHBOARD.events.changedClientAccountPassword);

      close();
    } catch {}
  };

  const close = () => {
    onClose();
    reset();
  };

  return (
    <Modal title={`Change Password for ${client.name}`} open={open} onClose={close}>
      <form onSubmit={handleSubmit(handleChangePassword)}>
        <Input
          label="Email"
          type="text"
          name="email"
          value={client.email}
          readOnly={true}
          disabled={true}
          autoComplete="username"
        />

        <Input
          label="Password"
          type="password"
          placeholder="Enter Client's New Password"
          autoComplete="new-password"
          error={errors.password?.message}
          {...register('password')}
        />
        <Input
          label="Confirm Password"
          type="password"
          placeholder="Confirm Client's New Password"
          autoComplete="new-password"
          error={errors.confirmPassword?.message}
          {...register('confirmPassword')}
        />
        <ButtonRow paddingTop>
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </ButtonRow>
      </form>
    </Modal>
  );
};
