import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from '../components/Anchor/Link';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { useSetHeadings } from '../hooks/useSetHeadings';
import { OutletContext } from '../layouts/Public';
import styles from '../layouts/Public.module.css';
import { api } from '../redux/api';

interface ForgotPasswordFields {
  email: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('This field is required')
});

export const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const setHeadings = useOutletContext<OutletContext>();
  const [forgotPassword, { isLoading }] = api.endpoints.forgotPassword.useMutation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });

  const handleForgotPassword = async (values: ForgotPasswordFields) => {
    try {
      await forgotPassword({
        email: values.email
      }).unwrap();
      setEmailSent(true);
      setHeadings(
        'Check your email',
        "Thank you! You will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder."
      );
    } catch {}
  };

  useSetHeadings('Forgot password?', "We'll send you a link to change your password.");

  return (
    <>
      {!emailSent && (
        <form className={styles.form} onSubmit={handleSubmit(handleForgotPassword)}>
          <Input label="Email" type="email" error={errors.email?.message} {...register('email')} />
          <Button type="submit" className={styles.loginBtn} fullWidth loading={isLoading}>
            Reset password
          </Button>
        </form>
      )}
      <div className={(styles.helper, emailSent ? styles.marginTop40 : '')}>
        Go back to <Link to="/login">Login</Link>
      </div>
    </>
  );
};
