import React from 'react';
import styles from './ContainerBox.module.css';

interface ContainerBoxProps extends React.ComponentProps<'div'> {
  elevation?: number;
}

export const ContainerBox = ({ children, className, ...rest }: ContainerBoxProps) => {
  return (
    <div className={`${styles.root} ${className || ''}`} {...rest}>
      {children}
    </div>
  );
};
