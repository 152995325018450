import { CellClassParams, ColDef, GetQuickFilterTextParams, ValueFormatterParams, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { forwardRef, Ref } from 'react';
import { Range } from 'react-date-range';
import { Button } from '../../components/Button';
import { createComparator, Datatable, defaultFormatter } from '../../components/Datatable';
import { MetricType } from '../../redux/api.types';
import { currency, number } from '../../utils/format';
import { ActionCellRenderer } from './CellRenderers/ActionsCellRenderer';
import { LimitsCellRenderer } from './CellRenderers/LimitsCellRenderer';
import { MetricDetailsCellRenderer } from './CellRenderers/MetricDetailsCellRenderer';
import { NameCellRenderer } from './CellRenderers/NameCellRenderer';
import { getAbsoluteDifference, getRelativeDifference } from '../../utils/helpers';

interface Region {
  region: string;
  regionName: string;
  fromEmail: string | null;
  isMwsValid: boolean;
  isAdsValid: boolean;
}

interface ConnectedRegions {
  connections: Region[];
}

interface ValueDifference {
  value: number | null;
  prevValue: number | null;
  difference: number | null;
}

export interface Limit {
  name: string;
  current: number;
  max: number;
  isLimitReached: boolean;
}

export interface Client {
  id: number;
  name: string;
  email: string;
  markedForDeletion: boolean;
  connectedRegions: ConnectedRegions;
  mwsConnected: boolean;
  adsConnected: boolean;
  itemsSold: ValueDifference;
  revenue: ValueDifference;
  profit: ValueDifference;
  organicSales: ValueDifference;
  paidSales: ValueDifference;
  advertisingCost: ValueDifference;
  acos: ValueDifference | null;
  tacos: ValueDifference | null;
  limits: Limit[];
  anyLimitsReached: boolean;
}

interface ClientTotalRow {
  itemsSold: ValueDifference;
  revenue: ValueDifference;
  profit: ValueDifference;
  organicSales: ValueDifference;
  paidSales: ValueDifference;
  advertisingCost: ValueDifference;
  acos: ValueDifference | null;
  tacos: ValueDifference | null;

  readonly [index: string]: ValueDifference | null;
}

export interface Quotas {
  used: number;
  total: number;
}

interface ClientsDatatableProps {
  clients: Client[];
  range: Range;
  loading?: boolean;
  noRowsComponentParams: {
    openAddClientModal: () => void;
  };
}

export const columnIds = {
  id: 'id',
  email: 'email',
  name: 'name',
  itemsSold: 'itemsSold',
  revenue: 'revenue',
  profit: 'profit',
  organicSales: 'organicSales',
  paidSales: 'paidSales',
  advertisingCost: 'advertisingCost',
  acos: 'acos',
  tacos: 'tacos',
  limits: 'limits',
  actions: 'actions'
};

export const isNA = (value: number | string, data: Client) =>
  value === null || value === '' || value === undefined || data?.markedForDeletion;

const calculateTotalRow = (clients: Client[]): ClientTotalRow => {
  const total: ClientTotalRow = {
    itemsSold: aggFunc(clients, columnIds.itemsSold, 'sum', 'relative'),
    revenue: aggFunc(clients, columnIds.revenue, 'sum', 'relative'),
    profit: aggFunc(clients, columnIds.profit, 'sum', 'relative'),
    organicSales: aggFunc(clients, columnIds.organicSales, 'sum', 'relative'),
    paidSales: aggFunc(clients, columnIds.paidSales, 'sum', 'relative'),
    advertisingCost: aggFunc(clients, columnIds.advertisingCost, 'sum', 'relative'),
    acos: aggFunc(clients, columnIds.acos, 'avg', 'absolute'),
    tacos: aggFunc(clients, columnIds.tacos, 'avg', 'absolute')
  };

  return total;
};

// Aggregation function for the TOTAL footer row
const aggFunc = (
  clients: Client[],
  column: string,
  aggType: 'sum' | 'avg',
  differenceType: 'relative' | 'absolute'
): ValueDifference => {
  const result = { value: 0, prevValue: 0, difference: 0 };

  if (!clients || !clients.length) return result;

  let itemsWithData = 0;
  clients.reduce((acc, client) => {
    const clientRow = client as unknown as ClientTotalRow;
    if (clientRow && clientRow[column]) {
      itemsWithData++;
      acc.value += clientRow[column]?.value || 0;
      acc.prevValue += clientRow[column]?.prevValue || 0;
    }

    return acc;
  }, result);

  if (aggType === 'avg' && itemsWithData !== 0) {
    result.value /= itemsWithData;
    result.prevValue /= itemsWithData;
  }

  result.difference =
    differenceType === 'relative'
      ? getRelativeDifference(result.value, result.prevValue)
      : getAbsoluteDifference(result.value, result.prevValue);

  return result;
};

const setPinnedBottomRowData = (gridApi: GridApi) => {
  const clients: Client[] = [];

  gridApi.forEachNodeAfterFilter((rowNode) => {
    clients.push(rowNode.data);
  });

  const totalRow = calculateTotalRow(clients);
  gridApi.setPinnedBottomRowData([totalRow]);
};

const valueNumberFormatter = (params: ValueFormatterParams) =>
  defaultFormatter<number>(params.value, {
    formatter: (value) => number(value),
    isNA: (value) => isNA(value, params.data)
  });

const valueCurrencyFormatter = (params: ValueFormatterParams) =>
  defaultFormatter<number>(params.value, {
    formatter: (value) => currency(value, 'USD', 0, 0),
    isNA: (value) => isNA(value, params.data)
  });

const valuePercentFormatter = (params: ValueFormatterParams) =>
  defaultFormatter<number>(params.value, {
    formatter: (value) => `${number(value, 2)}%`,
    isNA: (value) => isNA(value, params.data)
  });

const defaultColDef: ColDef = {
  sortable: true,
  sortingOrder: ['desc', 'asc', null],
  suppressSizeToFit: true,
  getQuickFilterText: () => '',

  // Sort all n/a on bottom whether asc or desc
  comparator: createComparator(isNA)
};

const defaultCellClassRules = {
  'ag-cell-no-value': ({ value, data }: CellClassParams) => isNA(value, data)
};

const columnDefs: ColDef[] = [
  {
    colId: columnIds.email,
    field: columnIds.email,
    hide: true,
    getQuickFilterText: (params: GetQuickFilterTextParams) => params.value
  },
  {
    colId: columnIds.name,
    field: columnIds.name,
    headerName: 'Client Name',
    cellClassRules: defaultCellClassRules,
    cellRenderer: NameCellRenderer,
    getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
    sortingOrder: ['asc', 'desc', null],
    suppressSizeToFit: false,
    minWidth: 118,
    flex: 1
  },
  {
    colId: columnIds.itemsSold,
    field: `${columnIds.itemsSold}.value`,
    valueFormatter: valueNumberFormatter,
    headerName: 'Items Sold',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.ItemsSold,
      columnId: columnIds.itemsSold
    }),
    minWidth: 118
  },
  {
    colId: columnIds.revenue,
    field: `${columnIds.revenue}.value`,
    valueFormatter: valueCurrencyFormatter,
    headerName: 'Revenue',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.Revenue,
      columnId: columnIds.revenue
    }),
    minWidth: 110
  },
  {
    colId: columnIds.profit,
    field: `${columnIds.profit}.value`,
    valueFormatter: valueCurrencyFormatter,
    headerName: 'Profit',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.Profit,
      columnId: columnIds.profit
    }),
    minWidth: 90
  },
  {
    colId: columnIds.organicSales,
    field: `${columnIds.organicSales}.value`,
    valueFormatter: valueCurrencyFormatter,
    headerName: 'Organic Sales',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.OrganicSales,
      columnId: columnIds.organicSales
    }),
    minWidth: 138
  },
  {
    colId: columnIds.paidSales,
    field: `${columnIds.paidSales}.value`,
    valueFormatter: valueCurrencyFormatter,
    headerName: 'Paid Sales',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.PaidSales,
      columnId: columnIds.paidSales
    }),
    minWidth: 116
  },
  {
    colId: columnIds.advertisingCost,
    field: `${columnIds.advertisingCost}.value`,
    valueFormatter: valueCurrencyFormatter,
    headerName: 'Advertising Cost',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.AdvertisingCost,
      columnId: columnIds.advertisingCost,
      inverted: true
    }),
    minWidth: 158
  },
  {
    colId: columnIds.acos,
    field: `${columnIds.acos}.value`,
    valueFormatter: valuePercentFormatter,
    headerName: 'ACOS',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.Acos,
      columnId: columnIds.acos,
      inverted: true
    }),
    minWidth: 88
  },
  {
    colId: columnIds.tacos,
    field: `${columnIds.tacos}.value`,
    valueFormatter: valuePercentFormatter,
    headerName: 'TACoS',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellClassRules: defaultCellClassRules,
    cellRenderer: MetricDetailsCellRenderer,
    cellRendererParams: () => ({
      metric: MetricType.Tacos,
      columnId: columnIds.tacos,
      inverted: true
    }),
    minWidth: 93
  },
  {
    colId: columnIds.limits,
    field: `${columnIds.limits}.value`,
    headerName: 'Limits',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellRenderer: LimitsCellRenderer,
    sortable: false,
    width: 73
  },
  {
    colId: columnIds.actions,
    field: `${columnIds.actions}.value`,
    headerName: 'Actions',
    headerClass: 'ag-header-align-right',
    cellClass: 'ag-cell-align-right',
    cellRenderer: ActionCellRenderer,
    sortable: false,
    width: 83
  }
];

const ClientsDatatable = forwardRef(
  ({ clients, range, loading, noRowsComponentParams }: ClientsDatatableProps, ref: Ref<AgGridReact>) => {
    return (
      <Datatable
        ref={ref}
        rowData={clients}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        context={{ range }}
        loading={loading}
        noRowsComponent={NoRowsComponent}
        noRowsComponentParams={noRowsComponentParams}
        suppressContextMenu={true}
        onModelUpdated={(event) => event.newData && setPinnedBottomRowData(event.api)}
        onFilterChanged={(event) => setPinnedBottomRowData(event.api)}
        onGridSizeChanged={(event) => event.api.sizeColumnsToFit()}
        onFirstDataRendered={(event) => event.api.sizeColumnsToFit()}
        skippedAutoSizeColumns={[columnIds.name, columnIds.limits, columnIds.actions]}
        rowClassRules={{
          'ag-marked-for-deletion': ({ data }: { data: Client }) => data?.markedForDeletion
        }}
      />
    );
  }
);

ClientsDatatable.displayName = 'ClientsDatatable';

export { ClientsDatatable };

const NoRowsComponent = (props: { openAddClientModal: () => void }) => {
  return (
    <div>
      <p>You don&apos;t have any clients</p>
      <div>
        <Button icon="add" onClick={props.openAddClientModal}>
          Add Client
        </Button>
      </div>
    </div>
  );
};
