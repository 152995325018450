import { ClickAwayListener, Grid } from '@mui/material';
import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import zonguru from '../assets/images/zonguru-mini-logo.svg';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { Icon } from '../components/Icon';
import { AGENCY_PROFILE } from '../constants/tracker';
import { api } from '../redux/api';
import { logout } from '../redux/auth';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { tracker } from '../utils/tracker';
import { ChangePasswordModal } from './ChangePasswordModal';
import styles from './Private.module.css';

export const Private = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const [fetchNewBillingSession, { isLoading: isNewBillingSessionLoading }] =
    api.endpoints.fetchNewBillingSession.useMutation();
  const [isManageBillingConfirmationModalOpen, setIsManageBillingConfirmationModalOpen] = useState(false);
  const openManageBillingConfirmationModal = () => setIsManageBillingConfirmationModalOpen(true);
  const closeManageBillingConfirmationModal = () => setIsManageBillingConfirmationModalOpen(false);

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
    closeDropdownMenu();
  };
  const closeChangePasswordModal = () => setIsChangePasswordModalOpen(false);
  const openDropdownMenu = () => setIsDropdownOpen(true);
  const closeDropdownMenu = () => setIsDropdownOpen(false);

  const handleManageBilling = async () => {
    try {
      const billingSession = await fetchNewBillingSession().unwrap();
      window.open(billingSession, '_blank');
      closeDropdownMenu();
      closeManageBillingConfirmationModal();
      tracker.track(AGENCY_PROFILE.namespace, AGENCY_PROFILE.events.openedStripeCustomerPortal);
    } catch {}
  };

  return (
    <div className={styles.root}>
      {/* Sidebar */}
      <div className={styles.sidebar}>
        <NavLink to="/" className={styles.logo}>
          <img src={zonguru} />
        </NavLink>
        <div className={styles.menuItem}>
          <NavLink to="/" className={({ isActive }) => `${isActive ? styles.menuLinkActive : ''} ${styles.menuLink}`}>
            <Icon icon="dashboard" size={24} />
          </NavLink>
          <span>Dashboard</span>
        </div>
      </div>

      {/* Main */}
      <div className={styles.main}>
        {/* Header */}
        <div className={styles.header}>
          <div className={styles.welcome}>Welcome again!</div>
          <div className={`${styles.dropdownWrapper} ${isDropdownOpen ? styles.isDropdownOpen : ''}`}>
            <button
              onClick={openDropdownMenu}
              className={`${styles.dropdownButton} ${isDropdownOpen ? styles.isDropdownOpen : ''}`}
            >
              {currentUser?.agencyName}
              <Icon icon="caret-down" size={16} />
            </button>
            {isDropdownOpen && (
              <ClickAwayListener onClickAway={closeDropdownMenu}>
                <div className={styles.dropdownMenu}>
                  <button className={styles.dropdownMenuItem} onClick={openChangePasswordModal}>
                    <span>Change Password</span>
                    <Icon icon="password-change" size={20} />
                  </button>
                  <button
                    className={styles.dropdownMenuItem}
                    onClick={() => {
                      openManageBillingConfirmationModal();
                      closeDropdownMenu();
                    }}
                  >
                    <span>Manage Billing</span>
                    <Icon icon="subscription" size={16} />
                  </button>
                  <button className={styles.dropdownMenuItem} onClick={() => dispatch(logout())}>
                    Log Out
                    <Icon icon="logout" size={16} />
                  </button>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>

        {/* Content */}
        <div id="agency-dashboard-content" className={styles.content}>
          {currentUser?.hasActiveSubscription ? (
            <Outlet />
          ) : (
            <Grid container justifyContent="center">
              <h3>It looks like there is a problem with your account. Contact Customer Success to solve the issue.</h3>
            </Grid>
          )}
        </div>

        {/* Change Password Modal */}
        <ChangePasswordModal open={isChangePasswordModalOpen} onClose={closeChangePasswordModal} />
        <ConfirmationModal
          title="Manage Billing"
          open={isManageBillingConfirmationModalOpen}
          onClose={closeManageBillingConfirmationModal}
          onConfirm={handleManageBilling}
          loading={isNewBillingSessionLoading}
        >
          <>
            <div>
              You will be redirected to ZonGuru Payment Portal from where you can manage your billing.
              <br />
              Are you sure you want to continue?
            </div>
          </>
        </ConfirmationModal>
      </div>
    </div>
  );
};
