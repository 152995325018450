import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Client, isNA } from '../ClientsDatatable';
import { Tooltip } from '../../../components/Tooltip';
import styles from './WithPercentCellRenderer.module.css';
import { number } from '../../../utils/format';

interface WithPercentCellRendererParams extends ICellRendererParams {
  percent?: number;
  inverted?: boolean;
  children?: React.ReactNode;
  prevValue?: number;
}

export const WithPercentCellRenderer = ({
  percent,
  inverted,
  children,
  valueFormatted,
  value,
  data,
  prevValue,
  formatValue
}: WithPercentCellRendererParams) => {
  const client = data as Client;

  const prevValueFormatted = formatValue ? formatValue(prevValue) : prevValue;

  const getPercentColor = () => {
    if (!percent) return '';

    if ((percent > 0 && !inverted) || (percent < 0 && inverted)) {
      return styles.good;
    } else if ((percent < 0 && !inverted) || (percent > 0 && inverted)) {
      return styles.bad;
    }
  };

  const renderPercent = () => {
    if (percent || percent === 0) {
      return (
        <Tooltip title={prevValueFormatted} placement="bottom">
          <div className={`${styles.percent} ${getPercentColor()}`}>
            {percent > 0 && '+'}
            {number(percent, 2)}%
          </div>
        </Tooltip>
      );
    }

    return null;
  };

  return (
    <div className={`${styles.root} ag-group-value`}>
      <div>{children ? children : valueFormatted || value}</div>
      {!isNA(value, client) && renderPercent()}
    </div>
  );
};
