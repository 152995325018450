import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Link } from '../components/Anchor/Link';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { TOKEN } from '../constants/localStorage';
import { AGENCY_PROFILE } from '../constants/tracker';
import { useSetHeadings } from '../hooks/useSetHeadings';
import styles from '../layouts/Public.module.css';
import { api } from '../redux/api';
import { setCurrentUser, setToken } from '../redux/auth';
import { useAppDispatch } from '../redux/hooks';
import { tracker } from '../utils/tracker';

interface LoginFields {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('This field is required'),
  password: Yup.string().required('This field is required')
});

export const Login = () => {
  const dispatch = useAppDispatch();
  const [login, { isLoading: isLoggingIn }] = api.endpoints.login.useMutation();
  const [fetchCurrentUser, { isLoading: isFetchingCurrentUser }] = api.endpoints.me.useMutation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFields>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema)
  });

  const handleLogin = async (values: LoginFields) => {
    try {
      const token = await login({
        email: values.email,
        password: values.password
      }).unwrap();
      const currentUser = await fetchCurrentUser({ FbaToken: token }).unwrap();
      tracker.identify(currentUser, true);
      tracker.track(AGENCY_PROFILE.namespace, AGENCY_PROFILE.events.loggedIn);
      dispatch(setCurrentUser(currentUser));
      dispatch(setToken(token));
      localStorage.setItem(TOKEN, token);
    } catch {}
  };

  useSetHeadings('Login to your admin account');

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(handleLogin)}>
        <Input label="Email" type="email" error={errors.email?.message} {...register('email')} />
        <Input label="Password" type="password" error={errors.password?.message} {...register('password')} />
        <Button type="submit" className={styles.loginBtn} fullWidth loading={isLoggingIn || isFetchingCurrentUser}>
          Log In
        </Button>
      </form>
      <div className={styles.helper}>
        Forgot your Password? <Link to="/forgot-password">Click here</Link>
      </div>
    </>
  );
};
