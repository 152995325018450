export const familyBody = '"Nunito", sans-serif';
export const weightRegular = '400';
export const weightSemibold = '600';
export const weightBold = '700';
export const sizeH1 = '36px';
export const sizeH2 = '28px';
export const sizeH3 = '25px';
export const sizeH4 = '18px';
export const sizeSubtitle = '16px';
export const sizeBody = '14px';
export const sizeCta = '12px';
export const sizeLabel = '10px';
export const lineHeight = '1.42857';
